import {take, put, select} from 'redux-saga/effects';
import {efDamageNodeTypes, efServiceTypePriorities, efServiceTypeSelectionTypes} from '@ace-de/eua-entity-types';
import * as savActionTypes from '../savActionTypes';
import * as serviceCaseActionTypes from '../../service-cases/serviceCaseActionTypes';
import isACEPartnerFromDACH from '../../service-assignments/isACEPartnerFromDACH';

/**
 * Method for filtering nodes that are service types
 * @param {array} nodeList
 * @param {array} nodeStructure
 * @return {array}
 * */
const mapServiceType = (nodeList, nodeStructure) => {
    return nodeList.filter(node => nodeStructure[node].nodeType === efDamageNodeTypes.SERVICE);
};

// Note: check if node is child of some other selected parent
//  (currently not the case, but it could be at some point in future)
/**
 * Recursive method that maps all removed nodes along with their child nodes
 * @param {array|int[]} removeIdx
 * @param {array} nodeStructure
 * @param {array} currentNodesSnapshot
 * @return {array}
 */
const mapForRemoval = (removeIdx, nodeStructure, currentNodesSnapshot) => {
    const forRemoval = [];
    if (Array.isArray(removeIdx)) {
        // when no children
        if (removeIdx[0] === null) {
            return [];
        }

        removeIdx.forEach(idx => {
            forRemoval.push(idx, ...mapForRemoval(idx, nodeStructure, currentNodesSnapshot));
        });
    }

    if (!Array.isArray(removeIdx)) {
        Object.keys(nodeStructure).forEach(node => {
            const nodeId = parseInt(node, 10);

            if (currentNodesSnapshot.includes(nodeId) && nodeId === removeIdx) {
                const nodeChildren = nodeStructure[nodeId].childNodeIds;
                forRemoval.push(nodeId, ...mapForRemoval(nodeChildren, nodeStructure, currentNodesSnapshot));
            }
        });
    }
    return forRemoval;
};

/**
 * Removing a list of nodes from the current state of selected nodes
 *
 * @param {array} selectedNodes
 * @param {array} forRemoval
 * @return array
 */
const removeRecords = (selectedNodes, forRemoval) => {
    if (!forRemoval) return selectedNodes;

    return selectedNodes.filter(node => {
        return !forRemoval.includes(node);
    });
};

const createDamageDescriptionString = (damageNodeSnapshots, damageNodes, translate, translateToEnglish) => {
    let snapshotIds = [...damageNodeSnapshots];
    const description = [];
    damageNodeSnapshots.forEach(nodeId => {
        if (!snapshotIds.includes(nodeId)) return;
        const node = damageNodes[nodeId];
        // always add answer node: also include root node (schadenkategorie)
        if (node.nodeType === efDamageNodeTypes.ANSWER) {
            translateToEnglish ? description.push(translate(node.name, {}, 'en-US'))
                : description.push(translate(node.name));
            description.push('\n');
            return;
        }
        // add desicion node
        // also add all selected children
        // and remove childrenIds from snapshotIds
        if (node.nodeType === efDamageNodeTypes.DECISION) {
            const childrenInSnapshot = node.childNodeIds.filter(childId => damageNodeSnapshots.includes(childId));
            if (childrenInSnapshot.length) {
                translateToEnglish ? description.push(translate(node.name, {}, 'en-US'), ' ')
                    : description.push(translate(node.name), ' ');
                childrenInSnapshot.forEach((childrenId, idx) => {
                    translateToEnglish ? description.push(translate(damageNodes[childrenId].name, {}, 'en-US'))
                        : description.push(translate(damageNodes[childrenId].name));
                    if (idx !== childrenInSnapshot.length - 1) {
                        description.push('***');
                    }
                    snapshotIds = snapshotIds.filter(nodeId => nodeId !== childrenId);
                });
                description.push('\n');
            }
        }
    });
    return description.toString().replaceAll(',', '').replaceAll('***', ', ');
};

const selectDamageDecisionTreeOptionsFlow = function* selectDamageDecisionTreeOptionsFlow() {
    const {serviceManager} = yield select(state => state.application);
    const i18nService = serviceManager.loadService('i18nService');
    const {translate} = i18nService;
    while (true) {
        const {payload} = yield take([savActionTypes.SELECT_DECISION_TREE_OPTIONS]);
        const damageNodes = yield select(state => state.serviceCases.serviceTypeDamageNodes); // structure
        const damageNodeKeys = Object.keys(damageNodes); // node IDs, collected for easier iteration

        const {serviceCase, serviceAssignment, parentId, selectedOptions, damageType} = payload;
        const {id: serviceCaseId, serviceSelectionType} = serviceCase;
        const {serviceType: currentServiceType, damageNodeSnapshots} = serviceCase;
        const [currentServicePriority] = currentServiceType && damageNodeSnapshots?.length
            ? damageNodeSnapshots
                .filter(nodeId => (damageNodes[nodeId]?.nodeType === efDamageNodeTypes.SERVICE
                    && damageNodes[nodeId]?.name
                    && damageNodes[nodeId].name.split('.').pop().toUpperCase() === currentServiceType))
                // sort from highest to lowest priority (sort ascending)
                .sort((nodeA, nodeB) => nodeA.priority - nodeB.priority)
                .map(nodeId => damageNodes[nodeId]?.priority)
            : [efServiceTypePriorities.UNDEFINED];

        if (damageType) {
            yield put({
                type: serviceCaseActionTypes.SET_DAMAGE_TYPE,
                payload: {damageType, serviceCaseId},
            });
        }

        // very first node that contains all damage categories (1st panel in the UI)
        const firstNode = damageNodeKeys.find(damageNodeKey => {
            return damageNodes[damageNodeKey].parentNodeId.length === 1
                && damageNodes[damageNodeKey].parentNodeId[0] === null;
        });

        const acePartner = serviceAssignment?.acePartner;
        const isDACH = isACEPartnerFromDACH(acePartner);
        let newSymptomsSnapshot = [...damageNodeSnapshots];
        let newServiceType = 'UNDEFINED';
        let newServicePriority = efServiceTypePriorities.UNDEFINED;
        let newServiceTypeSelection = serviceSelectionType;
        let currentServiceNoLongerValid = false;
        let hasServiceType = false;

        // tier one, selecting damage category
        if (parentId === firstNode) {
            const tierOneSnapshot = [selectedOptions[0], ...damageNodes[selectedOptions[0]].childNodeIds];
            // clean all snapshots and set only the new  damage category
            newSymptomsSnapshot.splice(0, newSymptomsSnapshot.length, ...tierOneSnapshot || null);

            // if there was service type as a result of of damage tree selection, unset it
            if (serviceSelectionType === efServiceTypeSelectionTypes.DAMAGE_TREE) {
                yield put({
                    type: serviceCaseActionTypes.SET_SERVICE_TYPE,
                    payload: {
                        serviceCaseId,
                        serviceType: '',
                        serviceSelectionType: efServiceTypeSelectionTypes.DAMAGE_TREE,
                    },
                });
            }

            const description = createDamageDescriptionString(newSymptomsSnapshot, damageNodes, translate, !isDACH);
            yield put({
                type: serviceCaseActionTypes.SET_DAMAGE_DESCRIPTION,
                payload: {
                    serviceCaseId,
                    description,
                },
            });

            // Update with new damage tree snapshot
            yield put({
                type: serviceCaseActionTypes.SET_DAMAGE_NODE_SNAPSHOT,
                payload: {
                    serviceCaseId,
                    damageNodeSnapshots: newSymptomsSnapshot,
                },
            });
            continue;
        }

        // Covers multi select checkbox, if all of the options were deselected
        if (selectedOptions.length === 0) {
            newSymptomsSnapshot = removeRecords(damageNodeSnapshots, mapForRemoval(
                damageNodes[parentId].childNodeIds,
                damageNodes,
                damageNodeSnapshots,
            ));

            const allRemoved = damageNodeSnapshots.filter(snapshot => !newSymptomsSnapshot.includes(snapshot));

            // Check if there was a service type amongst deselected options
            if (serviceSelectionType === efServiceTypeSelectionTypes.DAMAGE_TREE) {
                allRemoved.forEach(unavailableNode => {
                    // check if any of removed nodes could be parent of service type
                    const serviceTypeNodes = mapServiceType(damageNodes[unavailableNode].childNodeIds, damageNodes);
                    if (serviceTypeNodes.length === 0) {
                        return;
                    }
                    currentServiceNoLongerValid = true;
                });

                // unset removed service type
                if (currentServiceNoLongerValid) {
                    yield put({
                        type: serviceCaseActionTypes.SET_SERVICE_TYPE,
                        payload: {
                            serviceCaseId,
                            serviceType: '',
                            serviceSelectionType: efServiceTypeSelectionTypes.DAMAGE_TREE,
                        },
                    });
                }
            }

            const description = createDamageDescriptionString(newSymptomsSnapshot, damageNodes, translate, !isDACH);
            yield put({
                type: serviceCaseActionTypes.SET_DAMAGE_DESCRIPTION,
                payload: {
                    serviceCaseId,
                    description,
                },
            });

            yield put({
                type: serviceCaseActionTypes.SET_DAMAGE_NODE_SNAPSHOT,
                payload: {
                    serviceCaseId,
                    damageNodeSnapshots: newSymptomsSnapshot,
                },
            });

            continue;
        }

        const newlyAdded = [];
        const noLongerAvailable = [];

        damageNodeKeys.forEach(node => {
            const nodeId = parseInt(node, 10);

            // map nodes that (added)
            // are a child of the node that's currently editing,
            // and are in the sent array
            // and aren't in the damageNodeSnapshots
            if (damageNodes[node].parentNodeId.includes(parentId)
               && !damageNodeSnapshots.includes(nodeId)
               && selectedOptions.includes(nodeId)
            ) {
                newlyAdded.push(nodeId);
            }

            // map nodes that (removed)
            // are a child of the node that's currently editing,
            // and aren't in the sent array
            // and are in the damageNodeSnapshots
            if (damageNodes[node].parentNodeId.includes(parentId)
               && damageNodeSnapshots.includes(nodeId)
               && !selectedOptions.includes(nodeId)
            ) {
                noLongerAvailable.push(nodeId);
            }
        });

        // if there's anything to be removed, map all child nodes of the removed node and filter the existing snapshot
        if (noLongerAvailable.length > 0) {
            newSymptomsSnapshot = removeRecords(damageNodeSnapshots, mapForRemoval(
                noLongerAvailable,
                damageNodes,
                damageNodeSnapshots,
            ));

            // collect all removed nodes (not just those that aren't
            const allRemoved = damageNodeSnapshots.filter(snapshot => !newSymptomsSnapshot.includes(snapshot));

            // perform this only if service is selected through damage tree
            if (serviceSelectionType === efServiceTypeSelectionTypes.DAMAGE_TREE) {
                allRemoved.forEach(unavailableNode => {
                    // check if any of removed nodes could be parent of service type
                    const serviceTypeNodes = mapServiceType(damageNodes[unavailableNode].childNodeIds, damageNodes);
                    if (serviceTypeNodes.length === 0) {
                        return;
                    }
                    // if any of removed SERVICE nodes has greater (or equal) priority than current service type
                    // or current service type does not exists
                    // set currentServiceNoLongerValid = true
                    currentServiceNoLongerValid = !!currentServiceType && !!serviceTypeNodes.find(nodeId => (
                        damageNodes[nodeId].priority <= currentServicePriority));
                });
            }
        }

        // if there's nothing new and amongst removed were service type, unset it
        if (newlyAdded.length === 0 && currentServiceNoLongerValid) {
            newServiceType = '';
            newServiceTypeSelection = efServiceTypeSelectionTypes.DAMAGE_TREE;
            hasServiceType = true;
            newServicePriority = efServiceTypePriorities.UNDEFINED;
            // edge case: currentServiceNoLongerValid but in selectedOptions array we have at least one ANSWER
            // check all SERVICES and set newServiceType equal to one with the highest priority
            if (selectedOptions.length > 0) {
                selectedOptions.forEach(nodeId => {
                    if (damageNodes[nodeId].nodeType === efDamageNodeTypes.ANSWER) {
                        const serviceNode = damageNodes[nodeId].childNodeIds[0];
                        if (damageNodes[serviceNode].nodeType === efDamageNodeTypes.SERVICE) {
                            newServiceType = newServiceType
                                ? (damageNodes[serviceNode].priority < newServicePriority
                                    ? damageNodes[serviceNode].name.split('.').pop().toUpperCase()
                                    : newServiceType)
                                : damageNodes[serviceNode].name.split('.').pop().toUpperCase();
                            newServicePriority = damageNodes[serviceNode].priority < newServicePriority
                                ? damageNodes[serviceNode].priority
                                : newServicePriority;
                        }
                    }
                });
            }
            // edge case: currentServiceNoLongerValid but in newSymptomsSnapshot array we have at least one
            // SERVICE node -> set newServiceType equal to one with the highest priority
            newSymptomsSnapshot.forEach(nodeId => {
                if (damageNodes[nodeId].nodeType === efDamageNodeTypes.SERVICE) {
                    newServiceType = newServiceType
                        ? (damageNodes[nodeId].priority < newServicePriority
                            ? damageNodes[nodeId].name.split('.').pop().toUpperCase()
                            : newServiceType)
                        : damageNodes[nodeId].name.split('.').pop().toUpperCase();
                    newServicePriority = damageNodes[nodeId].priority < newServicePriority
                        ? damageNodes[nodeId].priority
                        : newServicePriority;
                }
            });
        }

        if (newlyAdded.length > 0) {
            newlyAdded.forEach(id => {
                // add new node
                newSymptomsSnapshot.push(id);

                // collect child nodes of a selected node
                const childNodes = damageNodes[id].childNodeIds;

                // map all child nodes that are service types
                const serviceTypeNodes = mapServiceType(childNodes, damageNodes);

                // Iterate all possible services that are a result of a selected node
                serviceTypeNodes.forEach(serviceNode => {
                    // if the service type was selected through damage tree
                    // check node priorities and set new service type
                    if (newServiceTypeSelection === efServiceTypeSelectionTypes.DAMAGE_TREE
                        || !newServiceTypeSelection
                    ) {
                        // edge case: if the current service is no longer valid and
                        // there is a service node, but it has lower priority than the already visited (selected)
                        // service node, do nothing
                        const hasSiblingDependency = !!newSymptomsSnapshot
                            .find(nodeId => {
                                const currentNodeServiceType = damageNodes[serviceNode].name.split('.').pop().toUpperCase();
                                /* eslint-disable-next-line max-len */
                                const siblingNodeServiceType = damageNodes[nodeId].nodeType === efDamageNodeTypes.SERVICE
                                    ? damageNodes[nodeId].name.split('.').pop().toUpperCase()
                                    : null;
                                return !!damageNodes[nodeId].priority
                                    && damageNodes[nodeId].priority < damageNodes[serviceNode].priority
                                    && !!siblingNodeServiceType
                                    && currentNodeServiceType !== siblingNodeServiceType;
                            });
                        if (((currentServiceNoLongerValid && !hasSiblingDependency)
                                || damageNodes[serviceNode].priority < currentServicePriority
                        ) && damageNodes[serviceNode].priority < newServicePriority
                        ) {
                            newServiceType = damageNodes[serviceNode].name.split('.').pop().toUpperCase();
                            newServicePriority = damageNodes[serviceNode].priority;
                            newServiceTypeSelection = efServiceTypeSelectionTypes.DAMAGE_TREE;
                            hasServiceType = true;
                        }
                    }

                    // collect optional/additional questions and put them to the damage node snapshot list
                    const additionalQuestions = damageNodes[serviceNode].childNodeIds;
                    newSymptomsSnapshot.push(...additionalQuestions);
                });

                // if the current service is no longer valid and there are no service nodes to replace and it was a
                // selection through the damage tree, clean up set service type
                if (currentServiceNoLongerValid
                    && serviceTypeNodes.length === 0
                    && newServiceTypeSelection === efServiceTypeSelectionTypes.DAMAGE_TREE
                ) {
                    newServiceType = '';
                    newServicePriority = efServiceTypePriorities.UNDEFINED;
                    newServiceTypeSelection = efServiceTypeSelectionTypes.DAMAGE_TREE;
                    hasServiceType = true;
                }

                newSymptomsSnapshot.push(...childNodes);
            });
        }

        // If there was a change of service through the damage tree, update status in store
        if (hasServiceType && newServiceTypeSelection === efServiceTypeSelectionTypes.DAMAGE_TREE) {
            yield put({
                type: serviceCaseActionTypes.SET_SERVICE_TYPE,
                payload: {
                    serviceCaseId,
                    serviceType: newServiceType,
                    serviceSelectionType: newServiceTypeSelection,
                },
            });
        }

        const description = createDamageDescriptionString(newSymptomsSnapshot, damageNodes, translate, !isDACH);
        yield put({
            type: serviceCaseActionTypes.SET_DAMAGE_DESCRIPTION,
            payload: {
                serviceCaseId,
                description,
            },
        });

        // set new damage node snapshot state
        yield put({
            type: serviceCaseActionTypes.SET_DAMAGE_NODE_SNAPSHOT,
            payload: {
                serviceCaseId,
                damageNodeSnapshots: newSymptomsSnapshot,
            },
        });
    }
};

export default selectDamageDecisionTreeOptionsFlow;
