import React, {Fragment, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useRouteUnmountEffect} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {ampMemberStatusTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, NumberInputField, ToggleSwitch, Form} from '@ace-de/ui-components';
import config from '../../config';
import * as sarcActionTypes from '../sarcActionTypes';
import sarcBudgetCalculationMatrix from '../sarcBudgetCalculationMatrix';
import getPrice from '../../utils/getPrice';

const SARCBudgetPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, activeLocale} = useTranslate();
    const translateTab = createTranslateShorthand('sarc_requirements_tab');
    const {serviceAssignment, numberOfDays, submitSARCBudgetForm, serviceCase} = props;
    const {memberStatus, isDamageLocationInGermany, isDeliveryRequested} = props;
    const [budgetData, setBudgetData] = useState(serviceAssignment);

    const roundToAtMost2Decimals = num => {
        const m = Number((Math.abs(num) * 100).toPrecision(15));
        return Math.round(m) / 100 * Math.sign(num);
    };

    const countTotalCosts = useCallback(budgetPerDay => {
        if (numberOfDays === 0) return 0;
        if (isDamageLocationInGermany) {
            const budget = budgetPerDay || sarcBudgetCalculationMatrix[memberStatus].budgetPerDay;
            if (numberOfDays >= 1 && numberOfDays < config.RENTAL_CAR_MAX_DAYS) {
                const total = parseFloat(budget) * numberOfDays;
                return roundToAtMost2Decimals(total);
            } else if (numberOfDays >= config.RENTAL_CAR_MAX_DAYS) {
                const total = parseFloat(budget) * (config.RENTAL_CAR_MAX_DAYS - 1);
                return roundToAtMost2Decimals(total);
            }
        }
        return sarcBudgetCalculationMatrix[memberStatus].totalBudget;
    }, [numberOfDays, isDamageLocationInGermany, memberStatus]);

    const handleTabChange = useCallback(formValues => {
        const {overrideDefaultBudget, budgetPerDay, rentalBudget} = formValues;
        const serviceAssignmentData = {
            overrideDefaultBudget,
            budgetPerDay: overrideDefaultBudget ? budgetPerDay : sarcBudgetCalculationMatrix[memberStatus].budgetPerDay,
            serviceCaseId: serviceAssignment.serviceCaseId,
            budget: {
                rental: rentalBudget,
                delivery: isDeliveryRequested
                    ? (typeof serviceAssignment.budget?.delivery === 'number'
                        ? serviceAssignment.budget.delivery
                        : (typeof serviceCase?.remainingBudget === 'number'
                            ? serviceCase.remainingBudget
                            : config.DEFAULT_RENTAL_CAR_DELIVERY_BUDGET))
                    : null,
            },
        };

        submitSARCBudgetForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentData,
        });
    }, [
        submitSARCBudgetForm,
        serviceAssignment,
        memberStatus,
        isDeliveryRequested,
        serviceCase?.remainingBudget,
    ]);

    const handleFormChange = formValues => {
        setBudgetData({
            ...formValues,
            overrideDefaultBudget: !!formValues.overrideDefaultBudget,
        });
    };

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        if (!budgetData) {
            completeRouteUnmountSideEffect({
                caller: sarcActionTypes.SUBMIT_SARC_BUDGET_FORM,
            });
            return;
        }

        handleTabChange({
            ...budgetData,
            rentalBudget: countTotalCosts(budgetData.budgetPerDay),
        });
    }, [budgetData, handleTabChange, countTotalCosts, serviceAssignment]);

    return (
        <Panel title={translateTab('budget_panel_title.budget')}>
            <div className={cx(['global!ace-u-typography--variant-body-medium', 'global!ace-u-padding--bottom-24'])}>
                {translateTab('budget_panel_text.number_of_days')}&nbsp;
                {numberOfDays}
            </div>
            <Form name="sarcBudgetForm" onChange={handleFormChange}>
                {() => {
                    return (
                        <Fragment>
                            <NumberInputField
                                name="budgetPerDay"
                                label={translateTab('budget_panel_input_label.budget_per_day')}
                                value={budgetData.overrideDefaultBudget
                                    ? budgetData.budgetPerDay
                                    : (isDamageLocationInGermany
                                        ? sarcBudgetCalculationMatrix[memberStatus].budgetPerDay
                                        : roundToAtMost2Decimals(
                                            sarcBudgetCalculationMatrix[memberStatus].totalBudget / numberOfDays,
                                        ))
                                }
                                isDisabled={!budgetData.overrideDefaultBudget}
                                className={cx(['global!ace-u-full-width', 'global!ace-u-padding--bottom-32'])}
                                min={0}
                            />
                            <ToggleSwitch
                                name="overrideDefaultBudget"
                                value={true}
                                isSelected={!!budgetData.overrideDefaultBudget}
                            >
                                {translateTab('budget_panel_toggle_switch_label.adjust_budget')}
                            </ToggleSwitch>
                            <div className={cx(['global!ace-u-padding--top-24', 'global!ace-u-padding--bottom-8'])}>
                                {translateTab('budget_panel_text.cost_coverage_sum')}
                            </div>
                            <div className={cx('global!ace-u-typography--variant-h2')}>
                                {getPrice(countTotalCosts(budgetData.budgetPerDay), activeLocale)}
                            </div>
                        </Fragment>
                    );
                }}
            </Form>
        </Panel>
    );
};

SARCBudgetPanel.propTypes = {
    serviceAssignment: PropTypes.object,
    serviceCase: PropTypes.object,
    submitSARCBudgetForm: PropTypes.func.isRequired,
    numberOfDays: PropTypes.number,
    memberStatus: PropTypes.string,
    isDamageLocationInGermany: PropTypes.bool,
    isDeliveryRequested: PropTypes.bool,
};

SARCBudgetPanel.defaultProps = {
    serviceAssignment: {},
    serviceCase: {},
    numberOfDays: 0,
    memberStatus: ampMemberStatusTypes.EMPTY,
    isDamageLocationInGermany: false,
    isDeliveryRequested: false,
};

const mapDispatchToProps = dispatch => ({
    submitSARCBudgetForm: payload => dispatch({
        type: sarcActionTypes.SUBMIT_SARC_BUDGET_FORM,
        payload,
    }),
});

export default connect(null, mapDispatchToProps)(SARCBudgetPanel);
