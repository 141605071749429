import React, {useState, useMemo, useCallback, useEffect, useRef, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import debounce from 'lodash.debounce';
import moment from 'moment';
import {withRouter, useRouteUnmountEffect} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {efRentalCarCategoryTypes, efAdditionalServiceRequestedTypes, efLocationTypes, efServiceAssignmentTypes, efServiceAssignmentStatusTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, Form, Divider, DateField, calendarIcon, ContentBlock, ContentItem, Pill} from '@ace-de/ui-components';
import {SelectField, Option, CheckboxGroup, Checkbox, RadioButtonGroup, RadioButton, InputField, InputPhoneNumberField, AutosuggestField} from '@ace-de/ui-components/form';
import {Icon, searchIcon, locationIcon} from '@ace-de/ui-components/icons';
import config from '../config';
import * as serviceCaseSelectors from '../service-cases/serviceCaseSelectors';
import * as serviceAssignmentSelectors from '../service-assignments/serviceAssignmentSelectors';
import * as sarcActionTypes from './sarcActionTypes';
import SARCBudgetPanel from './ui-elements/SARCBudgetPanel';
import RelevantLocationPanel from '../service-assignments/ui-elements/RelevantLocationPanel';

const checkArraysEquality = (array1 = [], array2 = []) => {
    if (!Array.isArray(array1) || !Array.isArray(array2)) return false;
    if (array1.length !== array2.length) return false;
    return array1.every(el => array2.includes(el));
};

const SARCRequirementsTab = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate, activeLocale} = useTranslate();
    const translateTab = createTranslateShorthand('sarc_requirements_tab');
    const {serviceAssignment, serviceAssignments, serviceCase, submitSARCRequirementsForm} = props;
    const {searchSARCDeliveryLocationGeolocation, searchSARCDropOffLocationGeolocation} = props;
    const {calculateMemberLocationToDeliveryLocationDistance} = props;
    const {submitSARCMemberLocationForm} = props;
    const [rentalCarData, setRentalCarData] = useState(serviceAssignment);
    const currentDeliveryLocationAddress = useRef('');
    const lastDeliveryLocationSearchQuery = useRef('');
    const currentDropOffLocationAddress = useRef('');
    const lastDropOffLocationSearchQuery = useRef('');
    const isRequirementsFormTouched = useRef(false);
    const [newDeliveryLocation, setNewDeliveryLocation] = useState(null);
    const [newDropOffLocation, setNewDropOffLocation] = useState(null);
    const [memberLocation, setMemberLocation] = useState(serviceAssignment.memberLocation || null);
    const searchSARCDeliveryLocationGeolocationDebounced = useMemo(
        () => debounce(searchSARCDeliveryLocationGeolocation, config.ARCGIS_ADDRESS_SUGGEST_GEOLOCATION_DEBOUNCE_TIMER),
        [searchSARCDeliveryLocationGeolocation],
    );
    const searchSARCDropOffLocationGeolocationDebounced = useMemo(
        () => debounce(searchSARCDropOffLocationGeolocation, config.ARCGIS_ADDRESS_SUGGEST_GEOLOCATION_DEBOUNCE_TIMER),
        [searchSARCDropOffLocationGeolocation],
    );
    const vehicleServiceAssignments = useMemo(() => (!serviceCase || !serviceAssignments ? [] : serviceAssignments
        .filter(assignment => assignment.assignmentType === efServiceAssignmentTypes.VEHICLE)
        .sort((assignmentA, assignmentB) => {
            return moment(assignmentA.createdAt).isSameOrAfter(moment(assignmentB.createdAt)) ? 1 : -1;
        })), [serviceCase, serviceAssignments]);

    const calculateNumberOfDays = (startDate, endDate) => {
        const areDatesValid = moment(startDate).isValid() && moment(endDate).isValid()
            && moment(endDate).isAfter(moment(startDate));
        return areDatesValid
            ? moment(endDate).diff(moment(startDate), 'days')
            : 0;
    };

    useEffect(() => {
        if (newDeliveryLocation && memberLocation) {
            // calculate the distance between member's location and selected delivery location candidate
            calculateMemberLocationToDeliveryLocationDistance({
                serviceAssignmentId: `${serviceAssignment.serviceCaseId}-${serviceAssignment.lineNo}`,
                deliveryLocation: newDeliveryLocation,
                memberLocation,
            });
        }
    }, [
        newDeliveryLocation,
        memberLocation,
        calculateMemberLocationToDeliveryLocationDistance,
        serviceAssignment.lineNo,
        serviceAssignment.serviceCaseId,
    ]);

    useEffect(() => {
        setRentalCarData(prevState => ({
            ...prevState,
            callbackPhoneNo: serviceAssignment.callbackPhoneNo || '',
        }));
    }, [serviceAssignment.callbackPhoneNo]);

    useEffect(() => {
        if (!newDeliveryLocation
            && serviceAssignment
            && serviceAssignment.deliveryLocation) {
            setNewDeliveryLocation(serviceAssignment.deliveryLocation);
        }
    }, [newDeliveryLocation, serviceAssignment]);

    useEffect(() => {
        if (!newDropOffLocation
            && serviceAssignment
            && serviceAssignment.dropOffLocation) {
            setNewDropOffLocation(serviceAssignment.dropOffLocation);
        }
    }, [newDropOffLocation, serviceAssignment]);

    const handleDeliveryLocationSearchQueryChange = searchQueryString => {
        if (searchQueryString
            && searchQueryString.toLowerCase() !== currentDeliveryLocationAddress.current.toLowerCase()
            && searchQueryString.length >= config.MINIMUM_SEARCH_QUERY_LENGTH) {
            searchSARCDeliveryLocationGeolocationDebounced({
                searchQueryString,
                serviceAssignmentId: `${serviceAssignment.serviceCaseId}-${serviceAssignment.lineNo}`,
            });
            lastDeliveryLocationSearchQuery.current = searchQueryString;
        }
    };

    const handleDeliveryLocationCandidateSelect = locationCandidate => {
        currentDeliveryLocationAddress.current = locationCandidate.formattedAddress;
        setNewDeliveryLocation(locationCandidate);
    };

    const setNewDeliveryLocationFromTowingDestination = useCallback(newLocationType => {
        const vehicleServiceAssignment = vehicleServiceAssignments.length === 1 ? vehicleServiceAssignments[0]
            : vehicleServiceAssignments.filter(assignment => !!assignment.towingDestination)[newLocationType.replace(`${efLocationTypes.TOWING_DESTINATION} `, '') - 1];

        if (vehicleServiceAssignment && vehicleServiceAssignment.towingDestination) {
            const location = vehicleServiceAssignment.towingDestination
                .setLocationType(efLocationTypes.TOWING_DESTINATION);
            setNewDeliveryLocation(location);
        }
    }, [vehicleServiceAssignments]);

    const handleDeliveryLocationTypeChange = useCallback(newLocationType => {
        if (newLocationType.includes(efLocationTypes.TOWING_DESTINATION)) {
            setNewDeliveryLocationFromTowingDestination(newLocationType);
            return;
        }

        switch (newLocationType) {
            case efLocationTypes.DAMAGE_LOCATION: {
                const {damage} = serviceCase;
                if (damage && damage.location) {
                    const location = damage.location.setLocationType(efLocationTypes.DAMAGE_LOCATION);
                    setNewDeliveryLocation(location);
                }
                break;
            }

            case efLocationTypes.RESIDENCE_PLACE: {
                const {member} = serviceCase;
                const {residenceLocation} = member;
                const location = residenceLocation.setLocationType(efLocationTypes.RESIDENCE_PLACE);
                setNewDeliveryLocation(location);
                break;
            }

            case efLocationTypes.SELECT_LOCATION: {
                if (serviceAssignment.memberLocation
                    && serviceAssignment.memberLocation.locationType === efLocationTypes.SELECT_LOCATION) {
                    setNewDeliveryLocation(serviceAssignment.memberLocation);
                }
                break;
            }

            default:
            // no-op
        }
    }, [
        serviceCase,
        serviceAssignment.memberLocation,
        setNewDeliveryLocationFromTowingDestination,
    ]);

    const handleDropOffLocationSearchQueryChange = searchQueryString => {
        if (searchQueryString
            && searchQueryString.toLowerCase() !== currentDropOffLocationAddress.current.toLowerCase()
            && searchQueryString.length >= config.MINIMUM_SEARCH_QUERY_LENGTH) {
            searchSARCDropOffLocationGeolocationDebounced({
                searchQueryString,
                serviceAssignmentId: `${serviceAssignment.serviceCaseId}-${serviceAssignment.lineNo}`,
            });
            lastDropOffLocationSearchQuery.current = searchQueryString;
        }
    };

    const handleDropOffLocationCandidateSelect = locationCandidate => {
        currentDropOffLocationAddress.current = locationCandidate.formattedAddress;
        setNewDropOffLocation(locationCandidate);
    };

    const handleTabChange = useCallback(formValues => {
        const {assignmentType, ...restFormValues} = formValues;
        const serviceAssignmentData = {
            ...restFormValues,
            additionalServiceRequested: Array.isArray(restFormValues.additionalServiceRequested)
                && restFormValues.additionalServiceRequested.includes('') ? [] : restFormValues.additionalServiceRequested,
            ...(restFormValues.deliveryRequested
                ? !!newDeliveryLocation && {deliveryLocation: newDeliveryLocation}
                : {deliveryLocation: null}),
            ...(restFormValues.dropOffDifferentFromPickup && newDropOffLocation
                ? {dropOffLocation: newDropOffLocation}
                : (!restFormValues.dropOffDifferentFromPickup && memberLocation
                    ? {dropOffLocation: memberLocation}
                    : {})),
        };

        const areDatesChanged = serviceAssignment?.numberOfDays && formValues.numberOfDays
            && serviceAssignment?.numberOfDays !== formValues.numberOfDays;
        const areRequestedServicesChanged = !checkArraysEquality(
            serviceAssignment?.additionalServiceRequested,
            formValues?.additionalServiceRequested,
        );
        const isPriceRecalculationNeeded = serviceAssignment?.assignmentStatus === efServiceAssignmentStatusTypes.NEW
            && (areDatesChanged || areRequestedServicesChanged)
            && !!serviceAssignment?.sippCode
            && !!serviceAssignment?.acePartner?.id;

        submitSARCRequirementsForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentData,
            isPriceRecalculationNeeded,
        });
    }, [
        submitSARCRequirementsForm,
        serviceAssignment.lineNo,
        serviceAssignment.serviceCaseId,
        serviceAssignment.acePartner?.id,
        serviceAssignment.assignmentStatus,
        serviceAssignment.numberOfDays,
        serviceAssignment.sippCode,
        serviceAssignment.additionalServiceRequested,
        newDeliveryLocation,
        newDropOffLocation,
        memberLocation,
    ]);

    const handleOnChange = formValues => {
        if (!isRequirementsFormTouched.current) isRequirementsFormTouched.current = true;
        const dropOffDate = rentalCarData.pickupDate !== formValues.pickupDate
            ? moment(formValues.pickupDate).add(1, 'days').toISOString()
            : formValues.dropOffDate;
        setRentalCarData({
            ...formValues,
            dropOffDate,
            creditCardPresented: !!formValues['creditCardPresented'],
            ecCardPresented: !!formValues['ecCardPresented'],
            driversLicensePresented: !!formValues['driversLicensePresented'],
            idCardPresented: !!formValues['idCardPresented'],
            numberOfDays: calculateNumberOfDays(formValues.pickupDate, dropOffDate),
        });
    };

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        if (!rentalCarData || !isRequirementsFormTouched.current) {
            completeRouteUnmountSideEffect({
                caller: sarcActionTypes.SUBMIT_SARC_REQUIREMENTS_FORM,
            });
            return;
        }

        handleTabChange(rentalCarData);
    }, [rentalCarData, handleTabChange]);

    const handleLocationSaving = locationInfo => {
        if (!locationInfo) return;
        submitSARCMemberLocationForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceCase.id,
            serviceAssignmentData: {
                memberLocation: locationInfo.selectedLocation,
                pickupLocation: locationInfo.selectedLocation,
            },
        });
    };

    // if no service case or service assignment don't render
    if (!serviceCase || !serviceAssignment) return null;

    const {deliveryLocationCandidates, deliveryLocationSearchQuery} = serviceAssignment;
    const {dropOffLocationCandidates, dropOffLocationSearchQuery} = serviceAssignment;
    const deliveryLocationTypeOptions = [
        ...(serviceCase.damage.location.formattedAddress
            ? [efLocationTypes.DAMAGE_LOCATION]
            : []),
        ...(serviceCase.member.residenceLocation.formattedAddress
            ? [efLocationTypes.RESIDENCE_PLACE]
            : []),
        efLocationTypes.SELECT_LOCATION,
        ...(vehicleServiceAssignments.length > 1
            ? vehicleServiceAssignments
                .filter(assignment => !!assignment.towingDestination)
                .map((assignment, idx) => `${efLocationTypes.TOWING_DESTINATION} ${idx + 1}`)
            : (vehicleServiceAssignments[0]?.towingDestination ? [efLocationTypes.TOWING_DESTINATION] : [])),
    ];

    return (
        <ContentBlock className={cx('global!ace-u-margin--top-0')}>
            <ContentItem className={cx('ace-c-content-item--span-9')}>
                <Panel
                    title={translateTab('panel_title.requirement')}
                >
                    <p
                        className={cx([
                            'global!ace-u-typography--variant-body-bold',
                            'global!ace-u-margin--bottom-24',
                        ])}
                    >
                        {translateTab('text.rental_car')}
                    </p>
                    <Form
                        name="rentalCarRequirements"
                        onSubmit={handleTabChange}
                        onChange={handleOnChange}
                    >
                        {formValues => {
                            const getInitialTowingDestination = () => {
                                const index = vehicleServiceAssignments
                                    .filter(assignment => !!assignment.towingDestination).findIndex(assignment => {
                                        return assignment.towingDestination.formattedAddress === newDeliveryLocation.formattedAddress; // eslint-disable-line max-len
                                    });
                                if (index >= 0) {
                                    return `${efLocationTypes.TOWING_DESTINATION} ${index + 1}`;
                                }
                                return newDeliveryLocation.locationType; // placeholder will be shown
                            };
                            return (
                                <Fragment>
                                    <div
                                        className={cx([
                                            'global!ace-u-grid',
                                            'global!ace-u-margin--bottom-48',
                                        ])}
                                    >
                                        <DateField
                                            name="pickupDate"
                                            label={translateTab('input_label.pickup_date')}
                                            value={rentalCarData.pickupDate}
                                            placeholder={translate('global.select.placeholder')}
                                            icon={calendarIcon}
                                            locale={activeLocale}
                                            format="DD.MM.YYYY, HH:mm"
                                        />
                                        <DateField
                                            name="dropOffDate"
                                            label={translateTab('input_label.drop_off_date')}
                                            value={rentalCarData.dropOffDate}
                                            placeholder={translate('global.select.placeholder')}
                                            icon={calendarIcon}
                                            locale={activeLocale}
                                            format="DD.MM.YYYY, HH:mm"
                                        />
                                        <SelectField
                                            className={cx('global!ace-u-grid-column--span-4')}
                                            name="rentalCarCategory"
                                            placeholder={translate('global.select.placeholder')}
                                            label={translateTab('input_label.rental_car_category')}
                                            value={rentalCarData.rentalCarCategory}
                                        >
                                            {Object.keys(efRentalCarCategoryTypes).map(categoryType => (
                                                <Option
                                                    key={categoryType}
                                                    name={categoryType}
                                                    value={efRentalCarCategoryTypes[categoryType]}
                                                >
                                                    {translate(`global.rental_car_category_type.${snakeCase(efRentalCarCategoryTypes[categoryType])}`)}
                                                </Option>
                                            ))}
                                        </SelectField>
                                    </div>
                                    <div>
                                        <p
                                            className={cx([
                                                'global!ace-u-typography--variant-body-bold',
                                                'global!ace-u-margin--bottom-24',
                                            ])}
                                        >
                                            {translateTab('text.delivery_requested')}
                                        </p>
                                        <RadioButtonGroup
                                            name="deliveryRequested"
                                            value={rentalCarData.deliveryRequested}
                                        >
                                            <RadioButton
                                                className={cx('global!ace-u-margin--right-64')}
                                                name="deliveryRequestedYes"
                                                value={true}
                                            >
                                                {translateTab('radio_button_label.delivery_option_yes')}
                                            </RadioButton>
                                            <RadioButton
                                                name="deliveryRequestedNo"
                                                value={false}
                                            >
                                                {translateTab('radio_button_label.delivery_option_no')}
                                            </RadioButton>
                                        </RadioButtonGroup>
                                        {formValues['deliveryRequested'] && (
                                        <div
                                            className={cx([
                                                'global!ace-u-grid',
                                                'global!ace-u-margin--top-24',
                                            ])}
                                        >
                                            <SelectField
                                                name="deliveryLocationType"
                                                value={newDeliveryLocation
                                                    ? (vehicleServiceAssignments.length > 1
                                                        && newDeliveryLocation.locationType
                                                            .includes(efLocationTypes.TOWING_DESTINATION)
                                                        ? getInitialTowingDestination()
                                                        : newDeliveryLocation.locationType)
                                                    : null}
                                                onChange={handleDeliveryLocationTypeChange}
                                                label={translateTab('input_label.delivery_location_type')}
                                                placeholder={translate('global.select.placeholder')}
                                                className={cx(['global!ace-u-grid-column--span-3'])}
                                            >
                                                {deliveryLocationTypeOptions.map(locationType => (
                                                    <Option
                                                        key={locationType}
                                                        name={`${locationType}Option`}
                                                        value={locationType}
                                                    >
                                                        {vehicleServiceAssignments.length === 1
                                                            // eslint-disable-next-line max-len
                                                            ? translate(`global.location_type.${locationType.toLowerCase()}`)
                                                            : (locationType.includes(efLocationTypes.TOWING_DESTINATION)
                                                                ? locationType.replace(
                                                                    efLocationTypes.TOWING_DESTINATION,
                                                                    translate(`global.location_type.towing_destination`),
                                                                )
                                                                : translate(`global.location_type.${locationType.toLowerCase()}`))}
                                                    </Option>
                                                ))}
                                            </SelectField>
                                            <div
                                                className={cx([
                                                    'global!ace-u-grid-column--span-6',
                                                    'global!ace-u-flex',
                                                    'global!ace-u-flex--direction-column',
                                                    'global!ace-u-flex--justify-flex-start',
                                                ])}
                                            >
                                                <AutosuggestField
                                                    name="deliveryLocationSearchQuery"
                                                    value={newDeliveryLocation?.formattedAddress || ''}
                                                    isDisabled={formValues['deliveryLocationType'] !== efLocationTypes.SELECT_LOCATION}
                                                    onChange={handleDeliveryLocationSearchQueryChange}
                                                    onOptionSelect={handleDeliveryLocationCandidateSelect}
                                                    optionValueSelector={locationCandidate => {
                                                        return locationCandidate.formattedAddress;
                                                    }}
                                                    label={translateTab('input_label.delivery_location')}
                                                    placeholder={translateTab('placeholder.search_delivery_location')}
                                                    icon={searchIcon}
                                                    className={cx('global!ace-u-margin--bottom-8')}
                                                >
                                                    {(formValues['deliveryLocationSearchQuery'] || '').length >= config.MINIMUM_SEARCH_QUERY_LENGTH
                                                    // eslint-disable-next-line max-len
                                                    && deliveryLocationSearchQuery === lastDeliveryLocationSearchQuery.current
                                                        ? deliveryLocationCandidates
                                                            .slice(0, config.ARCGIS_ADDRESS_GEOLOCATION_RESULTS_COUNT)
                                                            .map((locationCandidate, index) => (
                                                                <Option
                                                                    key={index}
                                                                    name={`delivery-location-candidate-${index}`}
                                                                    value={locationCandidate}
                                                                >
                                                                    <Icon
                                                                        icon={locationIcon}
                                                                        className={cx('global!ace-u-margin--right-16')}
                                                                    />
                                                                    {locationCandidate.formattedAddress}
                                                                </Option>
                                                            )) : null}
                                                </AutosuggestField>
                                                {!!(serviceAssignment.deliveryLocationCandidateDistance
                                                && newDeliveryLocation
                                                && memberLocation) && (
                                                    <div
                                                        className={cx('global!ace-u-flex--align-self-center')}
                                                    >
                                                        <Pill
                                                            type="information"
                                                            className={cx('global!ace-u-typography--variant-caption')}
                                                        >
                                                            {translateTab('pill_label.distance_from_member_location_to_delivery_location')}&nbsp;
                                                            {Math.round(
                                                                serviceAssignment.deliveryLocationCandidateDistance,
                                                            )}
                                                            {translate('global.unit.km')}
                                                        </Pill>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        )}

                                        <p
                                            className={cx([
                                                'global!ace-u-typography--variant-body-bold',
                                                'global!ace-u-margin--48-0-24',
                                            ])}
                                        >
                                            {translateTab('text.different_drop_off_location')}
                                        </p>
                                        <RadioButtonGroup
                                            name="dropOffDifferentFromPickup"
                                            value={rentalCarData.dropOffDifferentFromPickup}
                                        >
                                            <RadioButton
                                                className={cx('global!ace-u-margin--right-64')}
                                                name="dropOffDifferentFromPickupYes"
                                                value={true}
                                            >
                                                {translateTab('radio_button_label.delivery_option_yes')}
                                            </RadioButton>
                                            <RadioButton
                                                name="dropOffDifferentFromPickupNo"
                                                value={false}
                                            >
                                                {translateTab('radio_button_label.delivery_option_no')}
                                            </RadioButton>
                                        </RadioButtonGroup>
                                        {formValues['dropOffDifferentFromPickup'] && (
                                        <div
                                            className={cx([
                                                'global!ace-u-grid',
                                                'global!ace-u-margin--top-24',
                                            ])}
                                        >
                                            <AutosuggestField
                                                name="dropOffLocationSearchQuery"
                                                value={newDropOffLocation?.formattedAddress || ''}
                                                onChange={handleDropOffLocationSearchQueryChange}
                                                onOptionSelect={handleDropOffLocationCandidateSelect}
                                                optionValueSelector={locationCandidate => {
                                                    return locationCandidate.formattedAddress;
                                                }}
                                                className={cx('global!ace-u-grid-column--span-6')}
                                                label={translateTab('input_label.drop_off_location')}
                                                placeholder={translateTab('placeholder.search_drop_off_location')}
                                                icon={searchIcon}
                                            >
                                                {(formValues['dropOffLocationSearchQuery'] || '').length >= config.MINIMUM_SEARCH_QUERY_LENGTH
                                                // eslint-disable-next-line max-len
                                                && dropOffLocationSearchQuery === lastDropOffLocationSearchQuery.current
                                                    ? dropOffLocationCandidates
                                                        .slice(0, config.ARCGIS_ADDRESS_GEOLOCATION_RESULTS_COUNT)
                                                        .map((locationCandidate, index) => (
                                                            <Option
                                                                key={index}
                                                                name={`drop-off-location-candidate-${index}`}
                                                                value={locationCandidate}
                                                            >
                                                                <Icon
                                                                    icon={locationIcon}
                                                                    className={cx('global!ace-u-margin--right-16')}
                                                                />
                                                                {locationCandidate.formattedAddress}
                                                            </Option>
                                                        )) : null}
                                            </AutosuggestField>
                                        </div>
                                        )}

                                        <p
                                            className={cx([
                                                'global!ace-u-typography--variant-body-bold',
                                                'global!ace-u-margin--48-0-24',
                                            ])}
                                        >
                                            {translateTab('text.additional_service_requested')}
                                        </p>
                                        <div
                                            className={cx([
                                                'global!ace-u-flex',
                                                'global!ace-u-flex--direction-column',
                                                'global!ace-u-flex--basis-50',
                                            ])}
                                        >
                                            <CheckboxGroup
                                                name="additionalServiceRequested"
                                                value={rentalCarData.additionalServiceRequested}
                                            >
                                                <div
                                                    className={cx([
                                                        'global!ace-u-grid',
                                                        'global!ace-u-margin--bottom-24',
                                                    ])}
                                                >
                                                    {Object.keys(efAdditionalServiceRequestedTypes)
                                                        .map((additionalService, index) => {
                                                        // eslint-disable-next-line max-len
                                                            const additionalServiceName = snakeCase(efAdditionalServiceRequestedTypes[additionalService]);
                                                            // eslint-disable-next-line max-len
                                                            const additionalServiceTranslateKey = `global.rental_car_additional_service_requested.${additionalServiceName}`;
                                                            const gridColumnStart = (index % 3) * 4 + 1;
                                                            // eslint-disable-next-line max-len
                                                            const additionalServiceType = efAdditionalServiceRequestedTypes[additionalService];

                                                            return (
                                                                <Checkbox
                                                                    key={additionalService}
                                                                    name={additionalService}
                                                                    value={additionalServiceType}
                                                                    className={cx(`global!ace-u-grid-column--${gridColumnStart}-span-2`)}
                                                                >
                                                                    {translate(additionalServiceTranslateKey)}
                                                                </Checkbox>
                                                            );
                                                        })}
                                                </div>
                                            </CheckboxGroup>
                                        </div>
                                        <Divider />
                                        <p
                                            className={cx([
                                                'global!ace-u-typography--variant-body-bold',
                                                'global!ace-u-margin--32-0-24',
                                            ])}
                                        >
                                            {translateTab('text.more_information')}
                                        </p>
                                        <div className={cx('global!ace-u-grid')}>
                                            <InputPhoneNumberField
                                                className={cx('global!ace-u-grid-column--span-3')}
                                                name="callbackPhoneNo"
                                                value={rentalCarData.callbackPhoneNo || ''}
                                                label={translateTab('input_label.callback_phone_no')}
                                                placeholder=""
                                            />
                                            <InputField
                                                className={cx('global!ace-u-grid-column--span-6')}
                                                name="additionalInformationText"
                                                value={rentalCarData.additionalInformationText}
                                                label={translateTab('input_label.additional_request_text')}
                                                placeholder={translateTab('input_placeholder.additional_request_text')}
                                            />
                                        </div>
                                        <div
                                            className={cx([
                                                'global!ace-u-flex',
                                                'global!ace-u-flex--align-flex-start',
                                                'global!ace-u-flex--direction-column',
                                                'global!ace-u-margin--top-48',
                                            ])}
                                        >
                                            <Checkbox
                                                name="creditCardPresented"
                                                value={true}
                                                className={cx('global!ace-u-margin--bottom-24')}
                                                isSelected={serviceCase.isCreditCardAvailable
                                                    || serviceAssignment.creditCardPresented}
                                            >
                                                {translateTab('checkbox_label.credit_card')}
                                            </Checkbox>
                                            <Checkbox
                                                name="ecCardPresented"
                                                value={true}
                                                className={cx('global!ace-u-margin--bottom-24')}
                                                isSelected={serviceAssignment.ecCardPresented}
                                            >
                                                {translateTab(`checkbox_label.ec_card`)}
                                            </Checkbox>
                                            <Checkbox
                                                name="driversLicensePresented"
                                                value={true}
                                                className={cx('global!ace-u-margin--bottom-24')}
                                                isSelected={serviceAssignment.driversLicensePresented}
                                            >
                                                {translateTab('checkbox_label.driver_licence')}
                                            </Checkbox>
                                            <Checkbox
                                                name="idCardPresented"
                                                value={true}
                                                className={cx('global!ace-u-margin--bottom-24')}
                                                isSelected={serviceAssignment.idCardPresented}
                                            >
                                                {translateTab(`checkbox_label.identity_card`)}
                                            </Checkbox>
                                        </div>
                                    </div>
                                </Fragment>
                            );
                        }}
                    </Form>
                </Panel>
            </ContentItem>
            <ContentItem className={cx('ace-c-content-item--span-3')}>
                <RelevantLocationPanel
                    mapName="sarc-member-location"
                    handleLocationSaving={handleLocationSaving}
                    onRelevantLocationSelect={setMemberLocation}
                />
                <SARCBudgetPanel
                    serviceAssignment={serviceAssignment}
                    serviceCase={serviceCase}
                    numberOfDays={calculateNumberOfDays(rentalCarData?.pickupDate, rentalCarData?.dropOffDate)}
                    memberStatus={serviceCase.member.status}
                    isDamageLocationInGermany={serviceCase.damage?.location?.countryCode === 'DEU'}
                    isDeliveryRequested={!!rentalCarData?.deliveryRequested}
                />
            </ContentItem>
        </ContentBlock>
    );
};

SARCRequirementsTab.propTypes = {
    serviceAssignment: PropTypes.object,
    serviceAssignments: PropTypes.array,
    serviceCase: PropTypes.object,
    searchSARCDeliveryLocationGeolocation: PropTypes.func.isRequired,
    searchSARCDropOffLocationGeolocation: PropTypes.func.isRequired,
    submitSARCRequirementsForm: PropTypes.func.isRequired,
    calculateMemberLocationToDeliveryLocationDistance: PropTypes.func.isRequired,
    submitSARCMemberLocationForm: PropTypes.func.isRequired,
};

SARCRequirementsTab.defaultProps = {
    serviceAssignment: null,
    serviceAssignments: null,
    serviceCase: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    const serviceCaseAssignmentsSelector = serviceCaseSelectors.createServiceCaseAssignmentsSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
        serviceAssignments: serviceCaseAssignmentsSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    searchSARCDeliveryLocationGeolocation: payload => dispatch({
        type: sarcActionTypes.SEARCH_SARC_DELIVERY_LOCATION_GEOLOCATION,
        payload,
    }),
    searchSARCDropOffLocationGeolocation: payload => dispatch({
        type: sarcActionTypes.SEARCH_SARC_DROP_OFF_LOCATION_GEOLOCATION,
        payload,
    }),
    submitSARCRequirementsForm: payload => dispatch({
        type: sarcActionTypes.SUBMIT_SARC_REQUIREMENTS_FORM,
        payload,
    }),
    calculateMemberLocationToDeliveryLocationDistance: payload => dispatch({
        type: sarcActionTypes.CALCULATE_MEMBER_LOCATION_TO_DELIVERY_LOCATION_DISTANCE,
        payload,
    }),
    searchSARCMemberLocationGeolocation: payload => dispatch({
        type: sarcActionTypes.SEARCH_SARC_MEMBER_LOCATION_GEOLOCATION,
        payload,
    }),
    submitSARCMemberLocationForm: payload => dispatch({
        type: sarcActionTypes.SUBMIT_SARC_MEMBER_LOCATION_FORM,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SARCRequirementsTab));
