const sapTotalCostCalculationMatrix = {
    1: {
        costPerLightWeightThreshold: 250,
        costPerMediumWeightThreshold: 350,
        costPerHeavyWeightThreshold: 450,
    },
    2: {
        costPerLightWeightThreshold: 310,
        costPerMediumWeightThreshold: 434,
        costPerHeavyWeightThreshold: 558,
    },
    3: {
        costPerLightWeightThreshold: 350,
        costPerMediumWeightThreshold: 490,
        costPerHeavyWeightThreshold: 630,
    },
    4: {
        costPerLightWeightThreshold: 415,
        costPerMediumWeightThreshold: 581,
        costPerHeavyWeightThreshold: 747,
    },
    5: {
        costPerLightWeightThreshold: 475,
        costPerMediumWeightThreshold: 665,
        costPerHeavyWeightThreshold: 855,
    },
    6: {
        costPerLightWeightThreshold: 530,
        costPerMediumWeightThreshold: 742,
        costPerHeavyWeightThreshold: 954,
    },
};

export default sapTotalCostCalculationMatrix;
