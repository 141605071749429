import {produce, current} from 'immer';
import {DamageNode, Damage, efLocationTypes, Location, ECSHistory, ACEPartner, ServiceCaseLock} from '@ace-de/eua-entity-types';
import * as serviceCaseActionTypes from './serviceCaseActionTypes';
import createNewServiceCase from './createNewServiceCase';
import * as caseLogActionTypes from '../case-logs/caseLogActionTypes';
import config from '../config';

const initialState = {
    areSearchResultsValid: false,
    isServiceCaseCreationInProgress: false,
    serviceTypeDamageNodes: {},
    serviceCaseSearchResults: [],
    serviceCaseSearchResultsCount: 0,
    serviceCases: {},
    commissioners: {},
    remainingLockTime: config.DEFAULT_SERVICE_CASE_LOCK_TIME,
    vehicleInfos: {},
    caseLogFilterResults: [],
    caseLogFilterResultsCount: 0,
    caseLogFilterResultsPage: 0,
};

/**
 * Service cases reducer
 *
 * @param state
 * @param action
 * @returns {Object}
 */
const serviceCaseReducer = produce((draft, action) => {
    switch (action.type) {
        case serviceCaseActionTypes.SET_SERVICE_CASE_REMAINING_LOCK_TIME: {
            const {remainingLockTime} = action.payload;
            draft.remainingLockTime = remainingLockTime;
            break;
        }

        case serviceCaseActionTypes.SET_ARE_SEARCH_RESULTS_VALID: {
            const {areSearchResultsValid} = action.payload;
            draft.areSearchResultsValid = areSearchResultsValid;
            break;
        }

        case serviceCaseActionTypes.SET_SERVICE_CASE_CREATION_PROGRESS_STATE: {
            const {isServiceCaseCreationInProgress} = action.payload;
            draft.isServiceCaseCreationInProgress = isServiceCaseCreationInProgress;
            break;
        }

        case serviceCaseActionTypes.STORE_SERVICE_CASE_SEARCH_RESULTS: {
            const {serviceCaseSearchResults, serviceCaseSearchResultsCount} = action.payload;
            draft.serviceCaseSearchResults = serviceCaseSearchResults.map(serviceCaseDTO => {
                return createNewServiceCase(serviceCaseDTO.caseType).fromDTO(serviceCaseDTO);
            });
            draft.serviceCaseSearchResultsCount = serviceCaseSearchResultsCount;
            break;
        }

        // store member info in hash map
        case serviceCaseActionTypes.STORE_SERVICE_CASES: {
            const {serviceCaseDTOs} = action.payload;
            serviceCaseDTOs.forEach(serviceCaseDTO => {
                const serviceCase = draft.serviceCases[serviceCaseDTO.id];
                if (!serviceCase) {
                    const newServiceCase = createNewServiceCase(serviceCaseDTO.caseType).fromDTO(serviceCaseDTO);
                    draft.serviceCases[newServiceCase.id] = newServiceCase;
                    return;
                }
                draft.serviceCases[serviceCaseDTO.id] = serviceCase.fromDTO(serviceCaseDTO);
            });
            break;
        }

        case serviceCaseActionTypes.SET_SERVICE_CASE_PERSISTENCE_STATE: {
            const {serviceCaseId, persistenceState} = action.payload;
            const serviceCase = draft.serviceCases[serviceCaseId];
            if (serviceCase) {
                draft.serviceCases[serviceCaseId] = serviceCase.setPersistenceState(persistenceState);
            }
            break;
        }

        case serviceCaseActionTypes.SET_SERVICE_CASE_ASSIGNMENTS: {
            const {serviceAssignmentDTOs, serviceCaseId} = action.payload;
            let serviceCase = current(draft.serviceCases[serviceCaseId]);
            serviceAssignmentDTOs.forEach(serviceAssignmentDTO => {
                const {acePartner} = serviceAssignmentDTO;
                const {commissioner, member} = serviceCase;

                serviceCase = serviceCase.addServiceAssignmentId(`${serviceCase.id}-${serviceAssignmentDTO.lineNo}`);

                if (acePartner) {
                    serviceCase = serviceCase.setInvolvedParty(acePartner.id, acePartner);
                }

                if (commissioner) {
                    serviceCase = serviceCase.setInvolvedParty(commissioner.id, commissioner);
                }

                if (member) {
                    serviceCase = serviceCase.setInvolvedParty(member.membershipNo, member);
                }
            });
            draft.serviceCases[serviceCaseId] = serviceCase;
            break;
        }

        case serviceCaseActionTypes.STORE_SERVICE_TYPE_DAMAGE_NODES: {
            const {damageNodeDTOs} = action.payload;
            damageNodeDTOs.forEach(damageNodesDTO => {
                const damageNode = draft.serviceTypeDamageNodes[damageNodesDTO.id];

                if (!damageNode) {
                    const newDamageNode = new DamageNode().fromDTO(damageNodesDTO);
                    draft.serviceTypeDamageNodes[newDamageNode.id] = newDamageNode;
                    return;
                }

                damageNode.fromDTO(damageNodesDTO);
            });

            damageNodeDTOs.forEach(damageNodesDTO => {
                const parentNode = draft.serviceTypeDamageNodes[damageNodesDTO.parentNodeId];
                if (parentNode) {
                    draft.serviceTypeDamageNodes[parentNode.id] = parentNode.addChildId(damageNodesDTO.id);
                }
            });

            break;
        }

        case serviceCaseActionTypes.SET_DAMAGE_TYPE: {
            const {serviceCaseId, damageType} = action.payload;
            const draftServiceCase = draft.serviceCases[serviceCaseId];

            if (draftServiceCase) {
                draftServiceCase.damageType = damageType;
                draft.serviceCases[serviceCaseId] = draftServiceCase;
            }
            break;
        }

        case serviceCaseActionTypes.SET_SERVICE_TYPE: {
            const {serviceCaseId, serviceType, serviceSelectionType} = action.payload;
            const draftServiceCase = draft.serviceCases[serviceCaseId];

            if (draftServiceCase) {
                draftServiceCase.serviceType = serviceType;
                draftServiceCase.serviceSelectionType = serviceSelectionType;
                draft.serviceCases[serviceCaseId] = draftServiceCase;
            }
            break;
        }

        case serviceCaseActionTypes.SET_IS_CALLBACK_PHONE_NUMBER_VALID: {
            const {serviceCaseId, callbackPhoneNo, unknownCallbackPhoneNo, isCallbackPhoneNoValid} = action.payload;
            const draftServiceCase = draft.serviceCases[serviceCaseId];

            if (draftServiceCase) {
                draftServiceCase.callbackPhoneNo = callbackPhoneNo;
                draftServiceCase.unknownCallbackPhoneNo = unknownCallbackPhoneNo;
                draft.serviceCases[serviceCaseId] = draftServiceCase.setIsCallbackPhoneValid(isCallbackPhoneNoValid);
            }
            break;
        }

        case serviceCaseActionTypes.SET_DAMAGE_NODE_SNAPSHOT: {
            const {serviceCaseId, damageNodeSnapshots} = action.payload;
            const draftServiceCase = draft.serviceCases[serviceCaseId];

            if (draftServiceCase) {
                draftServiceCase.damageNodeSnapshots = damageNodeSnapshots;
                draft.serviceCases[serviceCaseId] = draftServiceCase;
            }
            break;
        }

        case serviceCaseActionTypes.CLEAR_DAMAGE_NODE_SNAPSHOT: {
            const {serviceCaseId} = action.payload;
            const draftServiceCase = draft.serviceCases[serviceCaseId];

            if (draftServiceCase) {
                draftServiceCase.damageNodeSnapshots = [];
                draft.serviceCases[serviceCaseId] = draftServiceCase;
            }
            break;
        }

        case serviceCaseActionTypes.SET_DAMAGE_DESCRIPTION: {
            const {serviceCaseId, description} = action.payload;
            const draftServiceCase = draft.serviceCases[serviceCaseId];

            if (draftServiceCase) {
                const draftDescription = draftServiceCase.damage;
                if (!draftDescription) {
                    draft.serviceCases[serviceCaseId].damage = new Damage({description});
                    return;
                }
                draftServiceCase.damage = draftDescription.setDescription(description);
                draft.serviceCases[serviceCaseId] = draftServiceCase;
            }
            break;
        }

        case serviceCaseActionTypes.STORE_SERVICE_CASE_COMMISSIONERS: {
            const {commissionerDTOs} = action.payload;
            commissionerDTOs.forEach(commissionerDTO => {
                const commissioner = draft.commissioners[commissionerDTO.id];
                if (!commissioner) {
                    const newCommissioner = new ACEPartner().fromDTO(commissionerDTO);
                    draft.commissioners[newCommissioner.id] = newCommissioner;
                    return;
                }
                draft.commissioners[commissionerDTO.id] = commissioner.fromDTO(commissionerDTO);
            });
            break;
        }

        case serviceCaseActionTypes.SET_SAV_REVERSE_GEOCODING_DAMAGE_LOCATION: {
            const {arcGISReverseGeocodingLocationDTO, serviceCaseId, locationType} = action.payload;
            const serviceCase = draft.serviceCases[serviceCaseId];
            const reverseGeocodingDamageLocation = new Location().fromDTO(arcGISReverseGeocodingLocationDTO);
            serviceCase.reverseGeocodingDamageLocation = reverseGeocodingDamageLocation
                .setLocationType(locationType);
            break;
        }

        case serviceCaseActionTypes.RESET_SAV_REVERSE_GEOCODING_DAMAGE_LOCATION: {
            const {serviceCaseId} = action.payload;
            const serviceCase = draft.serviceCases[serviceCaseId];
            serviceCase.reverseGeocodingDamageLocation = null;
            break;
        }

        case serviceCaseActionTypes.SET_SAV_DAMAGE_LOCATION_CANDIDATES: {
            const {locationKey, arcGISLocationCandidateDTOs, serviceCaseId, searchQueryString} = action.payload;
            const {locationType = null} = action.payload;
            const serviceCase = draft.serviceCases[serviceCaseId];
            serviceCase[`${locationKey}SearchQuery`] = searchQueryString;
            serviceCase[`${locationKey}Candidates`] = arcGISLocationCandidateDTOs
                .map(arcGISLocationCandidateDTO => {
                    const locationCandidate = new Location().fromDTO(arcGISLocationCandidateDTO);
                    return locationCandidate.setLocationType(locationType || efLocationTypes.SELECT_LOCATION);
                });
            break;
        }

        case caseLogActionTypes.STORE_CASE_LOGS: {
            const {caseLogFilterResults, serviceCaseId} = action.payload;
            const serviceCase = draft.serviceCases[serviceCaseId];

            const caseLogs = caseLogFilterResults.map(caseLogDTO => {
                return new ECSHistory().fromDTO(caseLogDTO);
            });

            draft.serviceCases[serviceCaseId] = serviceCase.setCaseLog(caseLogs);
            break;
        }

        case serviceCaseActionTypes.STORE_VEHICLE_MANUFACTURERS: {
            const {vehicleManufacturers, vehicleType} = action.payload;
            // since DAT IDs are integers, starting from 1, we have to change the format
            // in our system, so an agent can filter suggestions properly
            // linked issue: https://computerrock.atlassian.net/browse/ACEECS-3400
            const manufacturersMap = {};
            for (const [manufacturerId, manufacturerData] of Object.entries(vehicleManufacturers)) {
                manufacturersMap[`DAT-${manufacturerId}`] = manufacturerData;
            }
            draft.vehicleInfos[vehicleType] = manufacturersMap;
            break;
        }

        case serviceCaseActionTypes.STORE_VEHICLE_MODELS: {
            const {manufacturer, vehicleType, vehicleModels} = action.payload;
            // since DAT IDs are integers, starting from 1, we have to change the format
            // in our system, so an agent can filter suggestions properly
            // linked issue: https://computerrock.atlassian.net/browse/ACEECS-3400
            const modelsMap = {};
            for (const [modelId, modelData] of Object.entries(vehicleModels)) {
                modelsMap[`DAT-${modelId}`] = modelData;
            }
            draft.vehicleInfos[vehicleType][`DAT-${manufacturer}`].models = modelsMap;
            break;
        }

        case serviceCaseActionTypes.STORE_VEHICLE_VARIANTS: {
            const {manufacturer, vehicleType, vehicleVariants, baseModel} = action.payload;
            // since DAT IDs are integers, starting from 1, we have to change the format
            // in our system, so an agent can filter suggestions properly
            // linked issue: https://computerrock.atlassian.net/browse/ACEECS-3400
            const variantsMap = {};
            for (const [variantId, variantData] of Object.entries(vehicleVariants)) {
                variantsMap[`DAT-${variantId}`] = variantData;
            }
            draft.vehicleInfos[vehicleType][`DAT-${manufacturer}`].models[`DAT-${baseModel}`].variants = variantsMap;
            break;
        }

        case caseLogActionTypes.CLEAR_CASE_LOG_FILTER_RECORDS: {
            draft.caseLogFilterResults = [];
            break;
        }

        case serviceCaseActionTypes.STORE_CASE_LOG_FILTER_RESULTS: {
            const {caseLogFilterResults, serviceCaseId, caseLogFilterResultsCount, pageNumber} = action.payload;
            const serviceCase = draft.serviceCases[serviceCaseId];
            const caseLogs = caseLogFilterResults.map(caseLogDTO => {
                return new ECSHistory().fromDTO(caseLogDTO);
            });

            draft.caseLogFilterResults = caseLogs;
            draft.caseLogFilterResultsCount = typeof (caseLogFilterResultsCount) === 'number'
                ? caseLogFilterResultsCount
                : 0;
            draft.caseLogFilterResultsPage = pageNumber || 0;
            draft.serviceCases[serviceCaseId] = serviceCase.updateCaseLog(caseLogs);
            break;
        }

        case serviceCaseActionTypes.SET_SERVICE_CASE_LOCK_STATUS: {
            const {serviceCaseId, lockData} = action.payload;
            const draftServiceCase = draft.serviceCases[serviceCaseId];

            if (draftServiceCase) {
                draftServiceCase.lock = new ServiceCaseLock().fromDTO(lockData);
                draft.serviceCases[serviceCaseId] = draftServiceCase;
            }
            break;
        }

        case serviceCaseActionTypes.REMOVE_DELETED_SERVICE_ASSIGNMENTS: {
            const {serviceCaseId, serviceAssignmentLineNo} = action.payload;
            const draftServiceCase = draft.serviceCases[serviceCaseId];

            if (draftServiceCase) {
                draftServiceCase.serviceAssignmentIds = draftServiceCase.serviceAssignmentIds.filter(
                    serviceAssignmentId => {
                        return serviceAssignmentId !== `${serviceCaseId}-${serviceAssignmentLineNo}`;
                    },
                );
                draft.serviceCases[serviceCaseId] = draftServiceCase;
            }
            break;
        }

        default:
            // no-op
    }
}, initialState);

export default serviceCaseReducer;
