import React, {useCallback, Fragment, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useStyles, Panel, DataRow, NumberInput, TextArea, Option, Select, Form, ToggleSwitch, Pill} from '@ace-de/ui-components';
import {ampVehicleWeightTypes, efServiceAssignmentStatusTypes} from '@ace-de/eua-entity-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {withRouter, useRouteUnmountEffect} from '@computerrock/formation-router';
import sapTotalCostCalculationMatrix from '../sapTotalCostCalculationMatrix';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';
import * as serviceAssignmentSelectors from '../../service-assignments/serviceAssignmentSelectors';
import * as sapActionTypes from '../sapActionTypes';
import config from '../../config';

const CostCoverageCommissioningPanel = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand, activeLocale} = useTranslate();
    const translateTab = createTranslateShorthand('sap_cost_coverage_tab');
    const {calculatePickupRouteDistance, submitSAPCostCoverageCommissioningForm} = props;
    const {serviceCase, assignmentText, serviceAssignment, pickupCostData, routeDistance} = props;
    const {handlePickupCostDataChange, calculateTotalCost, onChangeHandler} = props;
    const damageDescriptionTextRows = serviceCase.damage && serviceCase.damage.description
        ? serviceCase.damage.description.split('\n') : [];

    useEffect(() => {
        if (!serviceAssignment.routeDistance) {
            // calculate the distance between selected destination and pickup location
            calculatePickupRouteDistance({
                serviceAssignmentId: `${serviceAssignment.serviceCaseId}-${serviceAssignment.lineNo}`,
                destination: serviceAssignment.destination,
                pickupLocation: serviceAssignment.pickupLocation,
                contractPartner: serviceAssignment.acePartner,
            });
        }
    }, [
        serviceAssignment,
        serviceAssignment.routeDistance,
        serviceAssignment.pickupLocation,
        serviceAssignment.destination,
        serviceAssignment.acePartner,
        calculatePickupRouteDistance,
        serviceAssignment.lineNo,
        serviceAssignment.serviceCaseId,
    ]);

    const handleTabChange = useCallback(formValues => {
        const {totalCost, tariffGroup, weight, calculatedPickupPrice, additionalCost, specialPickupPrice} = formValues;
        const serviceAssignmentData = {
            totalCost,
            tariffGroup,
            weight,
            additionalCost,
            pickupPrice: calculatedPickupPrice,
            specialPickupPrice,
            serviceCaseId: serviceAssignment.serviceCaseId,
            routeDistance: serviceAssignment.pickupLocation && serviceAssignment.destination
            && serviceAssignment.pickupRouteDistance
                ? Math.round(serviceAssignment.pickupRouteDistance)
                : routeDistance,
        };

        submitSAPCostCoverageCommissioningForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentData,
        });
    }, [
        routeDistance,
        submitSAPCostCoverageCommissioningForm,
        serviceAssignment,
    ]);

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        if (!pickupCostData) {
            completeRouteUnmountSideEffect({
                caller: sapActionTypes.SUBMIT_SAP_COST_COVERAGE_COMMISSIONING_FORM,
            });
            return;
        }

        handleTabChange({
            ...pickupCostData,
            totalCost: calculateTotalCost(),
        });
    }, [pickupCostData, handleTabChange, calculateTotalCost, serviceAssignment]);

    // if no service assignment, don't render
    if (!serviceAssignment) return null;

    const {pickupRouteDistance, pickupLocation, destination} = serviceAssignment;
    const areInputsDisabled = [
        efServiceAssignmentStatusTypes.ASSIGNED,
        efServiceAssignmentStatusTypes.DONE,
        efServiceAssignmentStatusTypes.ACCEPTED,
    ].includes(serviceAssignment.assignmentStatus);
    const isSpecialPriceDisabled = [
        efServiceAssignmentStatusTypes.DONE,
        efServiceAssignmentStatusTypes.ACCEPTED,
    ].includes(serviceAssignment.assignmentStatus);

    return (
        <Panel title={translateTab('panel_title.commissioning')}>
            <div className={cx('global!ace-u-margin--bottom-24')}>
                <DataRow label={translateTab('data_row_label.assignment_text')}>
                    <TextArea
                        className={cx('global!ace-u-flex--grow-1')}
                        isResizable={false}
                        value={assignmentText}
                        onChange={value => onChangeHandler('assignmentText', value)}
                    />
                </DataRow>
                <DataRow label={translateTab('data_row_label.damage_description')}>
                    <div>
                        {damageDescriptionTextRows.map(stringRow => (
                            <p key={stringRow}>
                                {stringRow}
                            </p>
                        ))}
                        <br />
                        {serviceCase.damageDescription}
                    </div>
                </DataRow>
            </div>
            <Form name="sapTotalCostForm" onChange={handlePickupCostDataChange}>
                {() => (
                    <Fragment>
                        <div className={cx(['global!ace-u-two-column-layout', 'global!ace-u-margin--bottom-24'])}>
                            <div>
                                <DataRow label={translateTab('data_row_label.pickup_location')}>
                                    {pickupLocation ? (
                                        <div className={cx(['global!ace-u-flex', 'global!ace-u-flex--direction-column'])}>
                                            <div>{serviceAssignment.pickupLocation.locationName}</div>
                                            <div>{serviceAssignment.pickupLocation.formattedAddress}</div>
                                            <div>{serviceAssignment.pickupLocationPhoneNo || ''}</div>
                                        </div>
                                    ) : '-'}
                                </DataRow>
                                <DataRow label={translateTab('data_row_label.pickup_location_fees')}>
                                    <div className={cx(['global!ace-u-flex', 'global!ace-u-flex--direction-column'])}>
                                        <div>{translateTab('text.stall_fee_per_day')}&nbsp;{serviceAssignment.stallFee?.toLocaleString(activeLocale) || '-'}</div>
                                        <div>{translateTab('text.other_fees_in_euro')}&nbsp;{serviceAssignment.otherFees?.toLocaleString(activeLocale) || '-'}</div>
                                    </div>
                                </DataRow>
                                <DataRow label={translateTab('data_row_label.tariff_groups')}>
                                    <Select
                                        name="tariffGroup"
                                        className={cx(['ace-c-select--small', 'global!ace-u-flex--grow-1'])}
                                        value={pickupCostData.tariffGroup}
                                        isDisabled={areInputsDisabled}
                                    >
                                        {Object.keys(sapTotalCostCalculationMatrix).map(tariffGroup => (
                                            <Option
                                                key={`${tariffGroup}Key`}
                                                name={`option${tariffGroup}`}
                                                value={tariffGroup}
                                            >
                                                {tariffGroup}
                                            </Option>
                                        ))}
                                    </Select>
                                </DataRow>
                                <DataRow label={translateTab('data_row_label.calculated_pickup_price')}>
                                    <NumberInput
                                        name="calculatedPickupPrice"
                                        className={cx(['ace-c-input--small', 'global!ace-u-flex--grow-1'])}
                                        value={pickupCostData.calculatedPickupPrice || ''}
                                        isDisabled={true}
                                        min={0}
                                    />
                                </DataRow>
                            </div>
                            <div>
                                <DataRow label={translateTab('data_row_label.destination')}>
                                    {destination ? (
                                        <div className={cx(['global!ace-u-flex', 'global!ace-u-flex--direction-column'])}>
                                            <div>{serviceAssignment.destination.locationName}</div>
                                            <div>{serviceAssignment.destination.formattedAddress}</div>
                                            <div>{serviceAssignment.destinationPhoneNo || ''}</div>
                                        </div>
                                    ) : '-'}
                                </DataRow>
                                <DataRow label={translateTab('data_row_label.distance')}>
                                    <div className={cx('global!ace-u-flex', 'global!ace-u-flex--align-center')}>
                                        <span className={cx('global!ace-u-margin--right-8')}>
                                            {pickupRouteDistance && pickupLocation && destination
                                                ? `${Math.round(pickupRouteDistance)} ${translate('global.unit.km')}`
                                                : `${routeDistance || '0'} ${translate('global.unit.km')}`}
                                        </span>
                                        {!(pickupLocation && destination && serviceAssignment?.acePartner) && (
                                            <Pill type="negative">
                                                {translateTab('pill_text.missing_fields')}
                                            </Pill>
                                        )}
                                    </div>
                                </DataRow>
                                <DataRow label={translateTab('data_row_label.tonnage')}>
                                    <Select
                                        name="weight"
                                        className={cx(['ace-c-select--small', 'global!ace-u-flex--grow-1'])}
                                        value={pickupCostData.weight}
                                        isDisabled={areInputsDisabled}
                                    >
                                        {Object.keys(ampVehicleWeightTypes).map(weightType => (
                                            <Option
                                                key={`${weightType}Key`}
                                                name={weightType}
                                                value={ampVehicleWeightTypes[weightType]}
                                            >
                                                {`${ampVehicleWeightTypes[weightType]?.toLocaleString(activeLocale)} ${translate('global.size_unit.tonne')}`}
                                            </Option>
                                        ))}
                                    </Select>
                                </DataRow>
                                <DataRow label={translateTab('data_row_label.additional_costs')}>
                                    <NumberInput
                                        name="additionalCost"
                                        className={cx(['ace-c-input--small', 'global!ace-u-flex--grow-1'])}
                                        value={pickupCostData.additionalCost || ''}
                                        isDisabled={areInputsDisabled}
                                        min={0}
                                    />
                                </DataRow>
                                <DataRow
                                    label={(
                                        <div
                                            className={cx([
                                                'global!ace-u-flex',
                                                'global!ace-u-flex--grow-1',
                                                'global!ace-u-flex--align-center',
                                            ])}
                                        >
                                            <p className={cx('global!ace-u-margin--right-16')}>
                                                {translateTab('data_row_label.different_pickup_price')}
                                            </p>
                                            <ToggleSwitch
                                                name="toggleSpecialPickupPrice"
                                                value={true}
                                                isSelected={!!pickupCostData.toggleSpecialPickupPrice}
                                                // specialPrice field should be enabled when assignment is ASSIGNED
                                                isDisabled={isSpecialPriceDisabled}
                                            />
                                        </div>
                                    )}
                                >
                                    <NumberInput
                                        name="specialPickupPrice"
                                        className={cx(['ace-c-input--small', 'global!ace-u-flex--grow-1'])}
                                        value={pickupCostData.specialPickupPrice || ''}
                                        // specialPrice field should be enabled when assignment is ASSIGNED
                                        isDisabled={!pickupCostData.toggleSpecialPickupPrice || isSpecialPriceDisabled}
                                        min={0}
                                    />
                                </DataRow>
                            </div>
                        </div>
                        <div className={cx('global!ace-u-two-column-layout')}>
                            <DataRow label={translateTab('data_row_label.total_cost')}>
                                {calculateTotalCost()?.toLocaleString(activeLocale, {
                                    style: 'currency',
                                    currency: config.CURRENCY,
                                }) || '-'}
                            </DataRow>
                        </div>
                    </Fragment>
                )}
            </Form>
        </Panel>
    );
};


CostCoverageCommissioningPanel.propTypes = {
    serviceCase: PropTypes.object,
    serviceAssignment: PropTypes.object,
    assignmentText: PropTypes.string,
    onChangeHandler: PropTypes.func.isRequired,
    submitSAPCostCoverageCommissioningForm: PropTypes.func.isRequired,
    pickupCostData: PropTypes.object.isRequired,
    routeDistance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    calculateTotalCost: PropTypes.func.isRequired,
    handlePickupCostDataChange: PropTypes.func.isRequired,
    calculatePickupRouteDistance: PropTypes.func.isRequired,
};

CostCoverageCommissioningPanel.defaultProps = {
    serviceCase: null,
    serviceAssignment: null,
    assignmentText: '',
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();

    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    calculatePickupRouteDistance: payload => dispatch({
        type: sapActionTypes.CALCULATE_PICKUP_ROUTE_DISTANCE,
        payload,
    }),
    submitSAPCostCoverageCommissioningForm: payload => dispatch({
        type: sapActionTypes.SUBMIT_SAP_COST_COVERAGE_COMMISSIONING_FORM,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CostCoverageCommissioningPanel));
