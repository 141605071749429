import {renderRoutes} from '@computerrock/formation-router';
import config from './config';
import routePaths from './routePaths';
import keycloakAuth from './keycloakAuth';
import arcGISAuth from './arcGISAuth';
import ecsAccessControl from './ecsAccessControl';
import * as applicationLoaderSagas from './application/applicationLoaderSagas';
import * as userProfileLoaderSagas from './user-profiles/userProfileLoaderSagas';
import * as memberLoaderSagas from './members/memberLoaderSagas';
import * as serviceCaseLoaderSagas from './service-cases/serviceCaseLoaderSagas';
import * as serviceAssignmentLoaderSagas from './service-assignments/serviceAssignmentLoaderSagas';
import * as taskLoaderSagas from './tasks/taskLoaderSagas';
import * as dashboardLoaderSagas from './dashboard/dashboardLoaderSagas';
import * as caseLogLoaderSagas from './case-logs/caseLogLoaderSagas';
import * as contractPartnerLoaderSagas from './contract-partners/contractPartnerLoaderSagas';
import ApplicationScreen from './application/ApplicationScreen';
import NotFoundScreen from './application/NotFoundScreen';
import AuthRedirectionScreen from './application/AuthRedirectionScreen';
import ShiftRoleSelectionScreen from './user-profiles/ShiftRoleSelectionScreen';
import DashboardSectionScreen from './dashboard/DashboardSectionScreen';
import DashboardScreen from './dashboard/DashboardScreen';
import MemberScreen from './members/MemberScreen';
import MemberSearchScreen from './members/MemberSearchScreen';
import TaskSearchScreen from './tasks/TaskSearchScreen';
import ServiceCaseSearchScreen from './service-cases/ServiceCaseSearchScreen';
import ServiceCaseScreen from './service-cases/ServiceCaseScreen';
import ServiceAssignmentVehicleScreen from './service-assignments-vehicle/ServiceAssignmentVehicleScreen';
import ServiceAssignmentRentalCarScreen from './service-assignments-rental-car/ServiceAssignmentRentalCarScreen';
import ServiceAssignmentAccommodationScreen from './service-assignments-accommodation/ServiceAssignmentAccommodationScreen';
import ServiceAssignmentShortTripScreen from './service-assignments-short-trip/ServiceAssignmentShortTripScreen';
import ServiceAssignmentReturnJourneyScreen from './service-assignments-return-journey/ServiceAssignmentReturnJourneyScreen';
import ServiceAssignmentSubstituteDriverScreen from './service-assignments-substitute-driver/ServiceAssignmentSubstituteDriverScreen';
import ServiceAssignmentMedicalClearanceScreen from './service-assignments-medical-clearance/ServiceAssignmentMedicalClearanceScreen';
import ServiceAssignmentSickPersonScreen from './service-assignments-sick-person/ServiceAssignmentSickPersonScreen';
import ServiceAssignmentVehicleReturnScreen from './service-assignments-vehicle-return/ServiceAssignmentVehicleReturnScreen';
import ServiceAssignmentVehicleAdditionalScreen from './service-assignments-vehicle-additional/ServiceAssignmentVehicleAdditionalScreen';
import ServiceAssignmentOtherServicesScreen from './service-assignments-other-services/ServiceAssignmentOtherServicesScreen';
import ServiceAssignmentPickupScreen from './service-assignments-pickup/ServiceAssignmentPickupScreen';
import ContractPartnerSearchScreen from './contract-partners/ContractPartnerSearchScreen';
import ContractPartnerScreen from './contract-partners/ContractPartnerScreen';
import AccessDeniedScreen from './application/AccessDeniedScreen';
import ServiceAssignmentTrailerReturnScreen from './service-assignments-trailer-return/ServiceAssignmentTrailerReturnScreen';
import ServiceAssignmentDeathEventHelpScreen from './service-assignments-death-event-help/ServiceAssignmentDeathEventHelpScreen';
import ServiceAssignmentMedicineShipmentScreen from './service-assignments-medicine-shipment/ServiceAssignmentMedicineShipmentScreen';
import ServiceAssignmentMedicalAdviceScreen from './service-assignments-medical-advice/ServiceAssignmentMedicalAdviceScreen';
import ServiceAssignmentLegalAdviceScreen from './service-assignments-legal-advice/ServiceAssignmentLegalAdviceScreen';
import MaintenanceScreen from './application/MaintenanceScreen';

/**
 * Location object definition (react-router, history)
 *
 * @typedef {Object} Location
 * @property {string} key - key is available for all by HashHistory
 * @property {string} pathname - path part of route
 * @property {string} search - search query part of route
 * @property {string} hash - hash (#) part of route
 * @property {Object} state - user defined state for the route
 */

/**
 * ConnectedRoute object definition (react-router-config, connected-react-router, redux-saga)
 *
 * @typedef {Object} ConnectedRoute
 * @property {string} path - any valid URL path that path-to-regexp understands.
 * @property {?Object} component - React component
 * @property {?function} render - render prop function
 * @property {?Location} location - for matching against different location than one in history
 * @property {?boolean} exact - when true, will only match if the path matches the location.pathname exactly
 * @property {?boolean} string - when true, a path that has a trailing slash will only match a location.pathname
 *                      with a trailing slash
 * @property {?boolean} sensitive - when true, will match if the path is case sensitive.
 * @property {?Array<ConnectedRoute>} routes - sub-routes
 * @property {?Array<Array<[saga, Object]>>} locationChangeSideEffects - Redux sagas (side-effects) to be run
 *                                            with payload object
 */

/**
 * Static route configuration
 *
 * @returns {Array<ConnectedRoute>}
 */
const getRoutes = () => [{
    component: ApplicationScreen,
    locationChangeSideEffects: [],
    routes: [
        window.DYNAMIC_ENV.IS_MAINTENANCE_MODE_ON
        && !config.IS_FALLBACK_SYSTEM // we don't need a maintenance screen on the ECS-FBS
        && window.location.hostname !== config.BACKDOOR_URL
            ? {
                path: '*',
                component: MaintenanceScreen,
            } : {
                path: routePaths.KEYCLOAK_AUTHORIZE,
                exact: true,
                component: AuthRedirectionScreen,
                locationChangeSideEffects: [
                    [keycloakAuth.authorize],
                ],
            },
        {
            path: routePaths.KEYCLOAK_AUTHENTICATE,
            exact: true,
            component: AuthRedirectionScreen,
            locationChangeSideEffects: [
                [keycloakAuth.authenticate],
            ],
        },
        {
            path: routePaths.ARCGIS_AUTHORIZE,
            exact: true,
            component: AuthRedirectionScreen,
            locationChangeSideEffects: [
                [arcGISAuth.authorize],
            ],
        },
        {
            path: routePaths.ARCGIS_AUTHENTICATE,
            exact: true,
            component: AuthRedirectionScreen,
            locationChangeSideEffects: [
                [arcGISAuth.authenticate],
            ],
        },
        {
            path: routePaths.SYSTEM_ACCESS_DENIED,
            exact: true,
            component: AccessDeniedScreen,
            locationChangeSideEffects: [
                [keycloakAuth.loadAuthSession],
            ],
        },
        {
            component: ({route}) => renderRoutes(route.routes),
            locationChangeSideEffects: [
                [applicationLoaderSagas.ensureRoute],
                [keycloakAuth.loadAuthSession],
                [userProfileLoaderSagas.loadUserProfile],
                [ecsAccessControl.authenticateSystemAccess],
                [arcGISAuth.loadAuthSession],
                [userProfileLoaderSagas.loadUserProfiles],
                [ecsAccessControl.authenticateRouteAccess],
                [serviceCaseLoaderSagas.loadServiceCaseLockStatus],
            ],
            routes: [
                {
                    path: routePaths.ROOT,
                    exact: true,
                    component: () => null,
                    locationChangeSideEffects: [],
                },
                {
                    path: routePaths.ROUTE_ACCESS_DENIED,
                    exact: true,
                    component: AccessDeniedScreen,
                    locationChangeSideEffects: [],
                },
                {
                    path: routePaths.SHIFT_ROLE_SELECTION,
                    exact: true,
                    component: ShiftRoleSelectionScreen,
                    locationChangeSideEffects: [],
                },
                {
                    path: routePaths.DASHBOARD_SECTION,
                    component: DashboardSectionScreen,
                    locationChangeSideEffects: [],
                    routes: [
                        {
                            path: routePaths.SERVICE_CASE_SEARCH,
                            exact: true,
                            component: ServiceCaseSearchScreen,
                            locationChangeSideEffects: [
                                [contractPartnerLoaderSagas.loadContractPartners],
                                [serviceCaseLoaderSagas.loadServiceCaseSearchResults],
                                [serviceCaseLoaderSagas.loadServiceCaseCommissioners],
                            ],
                        },
                        {
                            path: routePaths.DASHBOARD,
                            exact: true,
                            component: DashboardScreen,
                            locationChangeSideEffects: [
                                [dashboardLoaderSagas.startAutoRefreshTimer],
                                [dashboardLoaderSagas.loadLoggedInUsers],
                                [dashboardLoaderSagas.loadCurrentUsersTasks],
                                [dashboardLoaderSagas.loadCurrentUserWorkload],
                                [dashboardLoaderSagas.loadForeignAssistanceCases],
                                [dashboardLoaderSagas.loadMemberAppSubmittedCases],
                                [dashboardLoaderSagas.loadOpenServiceAssignments],
                                [dashboardLoaderSagas.loadSickPersonAndVehicleTransportAssignments],
                                [serviceCaseLoaderSagas.loadServiceCaseCommissioners],
                            ],
                        },
                        {
                            path: routePaths.MEMBER_SEARCH,
                            exact: true,
                            component: MemberSearchScreen,
                            locationChangeSideEffects: [
                                [memberLoaderSagas.loadMemberSearchResults],
                            ],
                        },
                        ...(!config.IS_FALLBACK_SYSTEM ? [
                            {
                                path: routePaths.TASK_SEARCH,
                                exact: true,
                                component: TaskSearchScreen,
                                locationChangeSideEffects: [
                                    [taskLoaderSagas.loadTaskSearchResults],
                                ],
                            },
                        ] : []),
                        {
                            path: routePaths.CONTRACT_PARTNER_SEARCH,
                            exact: true,
                            component: ContractPartnerSearchScreen,
                            locationChangeSideEffects: [
                                [contractPartnerLoaderSagas.loadContractPartnerSearchResults],
                                [contractPartnerLoaderSagas.loadContractPartnerAccountManagers],
                            ],
                        },
                        {
                            path: '*',
                            component: NotFoundScreen,
                        },
                    ],
                },
                {
                    path: routePaths.MEMBER,
                    component: MemberScreen,
                    locationChangeSideEffects: [
                        [memberLoaderSagas.loadMemberDetails],
                        [memberLoaderSagas.loadMemberVehicles],
                        [memberLoaderSagas.loadMemberServiceCases],
                        [memberLoaderSagas.loadMemberVehicleInfo],
                    ],
                },
                {
                    path: routePaths.CONTRACT_PARTNER,
                    exact: true,
                    component: ContractPartnerScreen,
                    locationChangeSideEffects: [
                        [contractPartnerLoaderSagas.loadContractPartner],
                        [contractPartnerLoaderSagas.loadContractPartnerTemporaryRestrictions],
                        [contractPartnerLoaderSagas.loadContractPartnerServiceCaseSearchResults],
                        [contractPartnerLoaderSagas.loadACECommissioners],
                        [contractPartnerLoaderSagas.loadContractPartnerAccountManagers],
                    ],
                },
                {
                    path: routePaths.SERVICE_CASE_SECTION,
                    component: ({route}) => renderRoutes(route.routes),
                    locationChangeSideEffects: [
                        [serviceCaseLoaderSagas.loadServiceCase],
                        [serviceCaseLoaderSagas.loadServiceCaseTaskCount],
                        [serviceAssignmentLoaderSagas.loadServiceTypeDamageNodes],
                        [serviceCaseLoaderSagas.loadServiceCaseToDoTaskCount],
                    ],
                    routes: [
                        {
                            path: routePaths.SERVICE_ASSIGNMENT_VEHICLE,
                            exact: true,
                            component: ServiceAssignmentVehicleScreen,
                            locationChangeSideEffects: [
                                [contractPartnerLoaderSagas.loadContractPartners],
                                [serviceCaseLoaderSagas.loadServiceCaseVehicleInfo],
                                [serviceAssignmentLoaderSagas.loadSAVMapLayers],
                                [serviceAssignmentLoaderSagas.loadSAVMapDamageLocationWatcher],
                                [serviceAssignmentLoaderSagas.loadSAVMapTowingDestinationWatcher],
                                [serviceAssignmentLoaderSagas.loadSAVMapContractPartnerWatcher],
                                [serviceAssignmentLoaderSagas.loadSAVNearbyTowingDestinations],
                                [serviceAssignmentLoaderSagas.loadSAVContractPartnerRecommendations],
                                [serviceAssignmentLoaderSagas.loadServiceAssignmentQMFeedbackDraft],
                                [serviceAssignmentLoaderSagas.loadServiceAssignmentFinalDestinationRoute],
                                [serviceAssignmentLoaderSagas.loadContractPartnerToDamageLocationRoute],
                            ],
                        },
                        {
                            path: routePaths.SERVICE_ASSIGNMENT_RENTAL_CAR,
                            exact: true,
                            component: ServiceAssignmentRentalCarScreen,
                            locationChangeSideEffects: [
                                [serviceAssignmentLoaderSagas.loadSARCMapLayers],
                                [serviceAssignmentLoaderSagas.loadSARCMapRentalCarStationWatcher],
                                [serviceAssignmentLoaderSagas.loadSARCNearbyRentalCarStations],
                                [serviceAssignmentLoaderSagas.loadSARCACEPartnerData],
                            ],
                        },
                        {
                            path: routePaths.SERVICE_ASSIGNMENT_ACCOMMODATION,
                            exact: true,
                            component: ServiceAssignmentAccommodationScreen,
                            locationChangeSideEffects: [
                                [serviceAssignmentLoaderSagas.loadSAAMapLayers],
                                [serviceAssignmentLoaderSagas.loadSAAMapAccommodationLocationWatcher],
                                [serviceAssignmentLoaderSagas.loadSAAAccommodationLocations],
                            ],
                        },
                        {
                            path: routePaths.SERVICE_ASSIGNMENT_SHORT_TRIP,
                            exact: true,
                            component: ServiceAssignmentShortTripScreen,
                            locationChangeSideEffects: [],
                        },
                        {
                            path: routePaths.SERVICE_ASSIGNMENT_RETURN_JOURNEY,
                            exact: true,
                            component: ServiceAssignmentReturnJourneyScreen,
                            locationChangeSideEffects: [
                                [serviceAssignmentLoaderSagas.loadServiceProviderData],
                            ],
                        },
                        {
                            path: routePaths.SERVICE_ASSIGNMENT_SUBSTITUTE_DRIVER,
                            exact: true,
                            component: ServiceAssignmentSubstituteDriverScreen,
                            locationChangeSideEffects: [
                                [serviceAssignmentLoaderSagas.loadServiceProviderData],
                                [serviceCaseLoaderSagas.loadServiceCaseVehicleInfo],
                            ],
                        },
                        {
                            path: routePaths.SERVICE_ASSIGNMENT_MEDICAL_CLEARANCE,
                            exact: true,
                            component: ServiceAssignmentMedicalClearanceScreen,
                            locationChangeSideEffects: [
                                [serviceAssignmentLoaderSagas.loadServiceProviderData],
                                [serviceCaseLoaderSagas.loadServiceCaseVehicleInfo],
                            ],
                        },
                        {
                            path: routePaths.SERVICE_ASSIGNMENT_SICK_PERSON,
                            exact: true,
                            component: ServiceAssignmentSickPersonScreen,
                            locationChangeSideEffects: [
                                [serviceAssignmentLoaderSagas.loadServiceProviderData],
                            ],
                        },
                        {
                            path: routePaths.SERVICE_ASSIGNMENT_VEHICLE_RETURN,
                            exact: true,
                            component: ServiceAssignmentVehicleReturnScreen,
                            locationChangeSideEffects: [
                                [serviceAssignmentLoaderSagas.loadServiceProviderData],
                            ],
                        },
                        {
                            path: routePaths.SERVICE_ASSIGNMENT_VEHICLE_ADDITIONAL,
                            exact: true,
                            component: ServiceAssignmentVehicleAdditionalScreen,
                            locationChangeSideEffects: [
                                [contractPartnerLoaderSagas.loadContractPartners],
                                [serviceAssignmentLoaderSagas.loadSAVAMapLayers],
                                [serviceAssignmentLoaderSagas.loadSAVAMapServiceLocationWatcher],
                                [serviceAssignmentLoaderSagas.loadSAVAMapTowingDestinationWatcher],
                                [serviceAssignmentLoaderSagas.loadSAVAMapContractPartnerWatcher],
                                [serviceAssignmentLoaderSagas.loadSAVANearbyTowingDestinations],
                                [serviceAssignmentLoaderSagas.loadSAVAContractPartnerRecommendations],
                                [serviceAssignmentLoaderSagas.loadServiceAssignmentQMFeedbackDraft],
                                [serviceAssignmentLoaderSagas.loadServiceAssignmentFinalDestinationRoute],
                                [serviceAssignmentLoaderSagas.loadContractPartnerToDamageLocationRoute],
                            ],
                        },
                        {
                            path: routePaths.SERVICE_ASSIGNMENT_OTHER_SERVICES,
                            exact: true,
                            component: ServiceAssignmentOtherServicesScreen,
                            locationChangeSideEffects: [
                                [serviceAssignmentLoaderSagas.loadServiceProviderData],
                            ],
                        },
                        {
                            path: routePaths.SERVICE_ASSIGNMENT_PICKUP,
                            exact: true,
                            component: ServiceAssignmentPickupScreen,
                            locationChangeSideEffects: [
                                [contractPartnerLoaderSagas.loadContractPartners],
                                [serviceAssignmentLoaderSagas.loadSAPMapLayers],
                                [serviceAssignmentLoaderSagas.loadSAPMapContractPartnerWatcher],
                                [serviceAssignmentLoaderSagas.loadSAPContractPartnerRecommendations],
                                [serviceAssignmentLoaderSagas.loadSAPMapPickupToTowingStaticRoute],
                                [serviceAssignmentLoaderSagas.loadServiceAssignmentQMFeedbackDraft],
                                [serviceAssignmentLoaderSagas.loadContractPartnerToDamageLocationRoute],
                            ],
                        },
                        {
                            path: routePaths.SERVICE_ASSIGNMENT_TRAILER_RETURN,
                            exact: true,
                            component: ServiceAssignmentTrailerReturnScreen,
                            locationChangeSideEffects: [
                                [serviceAssignmentLoaderSagas.loadServiceProviderData],
                            ],
                        },
                        {
                            path: routePaths.SERVICE_ASSIGNMENT_DEATH_EVENT_HELP,
                            exact: true,
                            component: ServiceAssignmentDeathEventHelpScreen,
                            locationChangeSideEffects: [
                                [serviceAssignmentLoaderSagas.loadServiceProviderData],
                                [serviceCaseLoaderSagas.loadServiceCaseVehicleInfo],
                            ],
                        },
                        {
                            path: routePaths.SERVICE_ASSIGNMENT_MEDICINE_SHIPMENT,
                            exact: true,
                            component: ServiceAssignmentMedicineShipmentScreen,
                            locationChangeSideEffects: [
                                [serviceAssignmentLoaderSagas.loadServiceProviderData],
                                [serviceCaseLoaderSagas.loadServiceCaseVehicleInfo],
                            ],
                        },
                        {
                            path: routePaths.SERVICE_ASSIGNMENT_MEDICAL_ADVICE,
                            exact: true,
                            component: ServiceAssignmentMedicalAdviceScreen,
                            locationChangeSideEffects: [
                                [serviceAssignmentLoaderSagas.loadSAMAMapLayers],
                                [serviceAssignmentLoaderSagas.loadSAMAMapDoctorLocationWatcher],
                                [serviceAssignmentLoaderSagas.loadSAMANearbyDoctors],
                                [serviceAssignmentLoaderSagas.loadSAMAMapServiceProviderWatcher],
                                [serviceAssignmentLoaderSagas.loadSAMAServiceProviders],
                            ],
                        },
                        {
                            path: routePaths.SERVICE_ASSIGNMENT_LEGAL_ADVICE,
                            exact: true,
                            component: ServiceAssignmentLegalAdviceScreen,
                            locationChangeSideEffects: [
                                [serviceAssignmentLoaderSagas.loadServiceProviderData],
                            ],
                        },
                        // should be last in this group of routes!
                        {
                            path: routePaths.SERVICE_CASE,
                            exact: true,
                            component: ServiceCaseScreen,
                            locationChangeSideEffects: [
                                [serviceCaseLoaderSagas.loadServiceCaseVehicleInfo],
                                [serviceCaseLoaderSagas.loadServiceCaseTasks],
                                [caseLogLoaderSagas.loadCaseLog],
                                [caseLogLoaderSagas.loadFilterCaseLogs],
                            ],
                        },
                    ],
                },
                {
                    path: '*',
                    component: NotFoundScreen,
                },
            ],
        },
    ],
}];

export default getRoutes;
