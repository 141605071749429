import React, {Fragment, useMemo} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {apmDayOfWeekTypes, apmContractPartnerEquipmentTypes, apmContractPartnerUndergroundParkingTypes, apmACEPartnerServiceTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, Divider} from '@ace-de/ui-components';
import {Table, TableBody, TableCell, TableHead, TableRow} from '@ace-de/ui-components/data-elements';
import {Icon, checkmarkIcon, closeIcon} from '@ace-de/ui-components/icons';
import * as contractPartnerSelectors from '../contractPartnerSelectors';

const handleMidnightFormat = endTime => {
    if (endTime !== '00:00' && endTime !== '24:00') return endTime;
    return endTime === '00:00' ? '24:00' : '00:00';
};

const ContractPartnerOperatingFeaturesPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateTab = createTranslateShorthand('contract_partner_services_tab');
    const {contractPartner} = props;

    const mondayToFridayWorkingHours = contractPartner?.waitingAreaWorkingHours?.find(workingHour => (
        workingHour.dayOfWeek === apmDayOfWeekTypes.MONDAY_TO_FRIDAY
    ));
    const saturdayWorkingHours = contractPartner?.waitingAreaWorkingHours?.find(workingHour => (
        workingHour.dayOfWeek === apmDayOfWeekTypes.SATURDAY
    ));
    const sundayHolidayWorkingHours = contractPartner?.waitingAreaWorkingHours?.find(workingHour => (
        workingHour.dayOfWeek === apmDayOfWeekTypes.SUNDAY_AND_HOLIDAY
    ));

    const workingHours = {
        MONDAY_TO_FRIDAY: {
            startTime: mondayToFridayWorkingHours?.from?.slice(0, -3) || '',
            endTime: handleMidnightFormat(mondayToFridayWorkingHours?.to?.slice(0, -3)) || '',
        },
        SATURDAY: {
            startTime: saturdayWorkingHours?.from?.slice(0, -3) || '',
            endTime: handleMidnightFormat(saturdayWorkingHours?.to?.slice(0, -3)) || '',
        },
        SUNDAY_AND_HOLIDAY: {
            startTime: sundayHolidayWorkingHours?.from?.slice(0, -3) || '',
            endTime: handleMidnightFormat(sundayHolidayWorkingHours?.to?.slice(0, -3)) || '',
        },
    };

    const cooperation = contractPartner?.rentalCar?.cooperation
        ? contractPartner.rentalCar.cooperation.filter(cooperator => !!cooperator) : [];

    const isCarDismantling = useMemo(() => {
        return !!(contractPartner.services?.find(service => {
            return service.serviceType === apmACEPartnerServiceTypes.CLEARANCE_SCRAPPING;
        })?.isCarDismantling);
    }, [contractPartner]);

    const usedCarStation = useMemo(() => {
        return !!(contractPartner.services?.find(service => {
            return service.serviceType === apmACEPartnerServiceTypes.CLEARANCE_SCRAPPING;
        })?.usedCarStation);
    }, [contractPartner]);

    const accommodation = useMemo(() => {
        return !!contractPartner.services?.find(service => {
            return service.serviceType === apmACEPartnerServiceTypes.ACCOMMODATION;
        });
    }, [contractPartner]);

    const personTransport = useMemo(() => {
        return !!contractPartner.services?.find(service => {
            return service.serviceType === apmACEPartnerServiceTypes.SHORT_TRIP;
        });
    }, [contractPartner]);

    const camperDimensions = useMemo(() => {
        return contractPartner?.services?.find(service => service?.camperDimensions)?.camperDimensions;
    }, [contractPartner]);

    // if no contract partner, don't render
    if (!contractPartner) return null;

    return (
        <Panel title={translateTab('panel_title.operating_features')}>
            <div className={cx(['global!ace-u-two-column-layout', 'global!ace-u-margin--bottom-48'])}>
                <div>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={10}>{translateTab('table_header.equipment')}</TableCell>
                                <TableCell colSpan={2} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.values(apmContractPartnerEquipmentTypes).map(cpEquipment => {
                                const hasCPEquipment = contractPartner.equipment?.length > 0
                                    ? contractPartner.equipment.includes(cpEquipment) : false;
                                return (
                                    <TableRow key={cpEquipment}>
                                        <TableCell colSpan={10}>
                                            {translateTab(`table_row_label.${cpEquipment.toLowerCase()}`)}
                                        </TableCell>
                                        <TableCell colSpan={2}>
                                            <Icon
                                                icon={hasCPEquipment ? checkmarkIcon : closeIcon}
                                                className={hasCPEquipment
                                                    ? cx('ace-c-icon--color-success')
                                                    : cx('ace-c-icon--color-disabled')}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                    <Divider />
                </div>
                <div>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={10}>
                                    {translateTab('table_header.usage_in_underground_parking')}
                                </TableCell>
                                <TableCell colSpan={2} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.values(apmContractPartnerUndergroundParkingTypes).map(undergroundParkingType => {
                                const hasCPundergroundParking = contractPartner.undergroundParking?.length > 0
                                    ? contractPartner.undergroundParking.includes(undergroundParkingType) : false;
                                return (
                                    <TableRow key={undergroundParkingType}>
                                        <TableCell colSpan={10}>
                                            {translateTab(`table_row_label.${undergroundParkingType.toLowerCase()}`)}
                                        </TableCell>
                                        <TableCell colSpan={2}>
                                            <Icon
                                                icon={hasCPundergroundParking ? checkmarkIcon : closeIcon}
                                                className={hasCPundergroundParking
                                                    ? cx('ace-c-icon--color-success')
                                                    : cx('ace-c-icon--color-disabled')}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                    <Divider />
                </div>
            </div>
            <div className={cx('global!ace-u-margin--bottom-48')}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={6}>{translateTab('table_header.rental_cars')}</TableCell>
                            <TableCell colSpan={8} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={6}>
                                {translateTab('table_row_label.direct_access_to_rental_cars')}
                            </TableCell>
                            <TableCell>
                                <Icon
                                    icon={contractPartner.rentalCar?.directAccessToRentalCars
                                        ? checkmarkIcon : closeIcon}
                                    className={contractPartner.rentalCar?.directAccessToRentalCars
                                        ? cx('ace-c-icon--color-success')
                                        : cx('ace-c-icon--color-disabled')}
                                />
                            </TableCell>
                            <TableCell colSpan={5} />
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={6}>
                                {translateTab('table_row_label.own_rental_cars_available')}
                            </TableCell>
                            <TableCell>
                                <Icon
                                    icon={contractPartner.rentalCar?.ownRentalCarsAvailable
                                        ? checkmarkIcon : closeIcon}
                                    className={contractPartner.rentalCar?.ownRentalCarsAvailable
                                        ? cx('ace-c-icon--color-success')
                                        : cx('ace-c-icon--color-disabled')}
                                />
                            </TableCell>
                            <TableCell colSpan={6}>
                                {translateTab('table_row_label.oneway_usage_possible')}
                            </TableCell>
                            <TableCell>
                                <Icon
                                    icon={contractPartner.rentalCar?.oneWayUsagePossible
                                        ? checkmarkIcon : closeIcon}
                                    className={contractPartner.rentalCar?.oneWayUsagePossible
                                        ? cx('ace-c-icon--color-success')
                                        : cx('ace-c-icon--color-disabled')}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={6}>
                                {translateTab('table_row_label.rental_car_cooperations')}
                            </TableCell>
                            <TableCell>
                                <Icon
                                    icon={Array.isArray(cooperation) && cooperation.length
                                        ? checkmarkIcon : closeIcon}
                                    className={Array.isArray(cooperation) && cooperation.length > 0
                                        ? cx('ace-c-icon--color-success')
                                        : cx('ace-c-icon--color-disabled')}
                                />
                            </TableCell>
                            <TableCell colSpan={5}>
                                {Array.isArray(cooperation)
                                    ? cooperation.join(', ') : ''}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={6}>
                                {translateTab('table_row_label.other_rental_car_cooperation')}
                            </TableCell>
                            <TableCell>
                                <Icon
                                    icon={contractPartner.rentalCar?.otherCooperation ? checkmarkIcon : closeIcon}
                                    className={contractPartner.rentalCar?.otherCooperation
                                        ? cx('ace-c-icon--color-success')
                                        : cx('ace-c-icon--color-disabled')}
                                />
                            </TableCell>
                            <TableCell colSpan={5}>{contractPartner.rentalCar?.otherCooperation || ''}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={6}>
                                {translateTab('table_row_label.oneway_usage_with_other_rental_car_cooperation_possible')}
                            </TableCell>
                            <TableCell>
                                <Icon
                                    icon={contractPartner.rentalCar?.oneWayUsageWithCooperation
                                        ? checkmarkIcon : closeIcon}
                                    className={contractPartner.rentalCar?.oneWayUsageWithCooperation
                                        ? cx('ace-c-icon--color-success')
                                        : cx('ace-c-icon--color-disabled')}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Divider />
            </div>
            <div className={cx('global!ace-u-margin--bottom-48')}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={6}>{translateTab('table_header.person_transport')}</TableCell>
                            <TableCell colSpan={2} />
                            <TableCell colSpan={6} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={6}>
                                {translateTab('table_row_label.person_transport_possible')}
                            </TableCell>
                            <TableCell colSpan={2}>
                                <Icon
                                    icon={personTransport ? checkmarkIcon : closeIcon}
                                    className={personTransport
                                        ? cx('ace-c-icon--color-success')
                                        : cx('ace-c-icon--color-disabled')}
                                />
                            </TableCell>
                            <TableCell colSpan={6} />
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={6}>
                                {translateTab('table_row_label.accommodation_possible')}
                            </TableCell>
                            <TableCell colSpan={2}>
                                <Icon
                                    icon={accommodation ? checkmarkIcon : closeIcon}
                                    className={accommodation
                                        ? cx('ace-c-icon--color-success')
                                        : cx('ace-c-icon--color-disabled')}
                                />
                            </TableCell>
                            <TableCell colSpan={6} />
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={6}>
                                {translateTab('table_row_label.customer_waiting_area_available')}
                            </TableCell>
                            <Fragment>
                                <TableCell colSpan={3}>
                                    <div className={cx('global!ace-u-flex')}>
                                        {translateTab('table_row_label.monday_friday')}
                                        {mondayToFridayWorkingHours && (
                                            <div className={cx('global!ace-u-margin--left-16')}>
                                                <span>
                                                    {workingHours.MONDAY_TO_FRIDAY.startTime}
                                                </span>
                                                <span>
                                                    {workingHours.MONDAY_TO_FRIDAY.startTime
                                                    && workingHours.MONDAY_TO_FRIDAY.endTime
                                                        ? '–' : translateTab('table_row_label.closed')}
                                                </span>
                                                <span>
                                                    {workingHours.MONDAY_TO_FRIDAY.endTime}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </TableCell>
                                <TableCell colSpan={3}>
                                    <div className={cx('global!ace-u-flex')}>
                                        {translateTab('table_row_label.saturday')}
                                        {saturdayWorkingHours && (
                                            <div className={cx('global!ace-u-margin--left-16')}>
                                                <span>
                                                    {workingHours.SATURDAY.startTime}
                                                </span>
                                                <span>
                                                    {workingHours.SATURDAY.startTime
                                                    && workingHours.SATURDAY.endTime
                                                        ? '–' : translateTab('table_row_label.closed')}
                                                </span>
                                                <span>
                                                    {workingHours.SATURDAY.endTime}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </TableCell>
                                <TableCell colSpan={2}>
                                    <div className={cx('global!ace-u-flex')}>
                                        {translateTab('table_row_label.sunday_holiday').slice(0, 2)}
                                        {sundayHolidayWorkingHours && (
                                            <div className={cx('global!ace-u-margin--left-16')}>
                                                <span>
                                                    {workingHours.SUNDAY_AND_HOLIDAY.startTime}
                                                </span>
                                                <span>
                                                    {workingHours.SUNDAY_AND_HOLIDAY.startTime
                                                    && workingHours.SUNDAY_AND_HOLIDAY.endTime
                                                        ? '–' : translateTab('table_row_label.closed')}
                                                </span>
                                                <span>
                                                    {workingHours.SUNDAY_AND_HOLIDAY.endTime}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </TableCell>
                            </Fragment>
                        </TableRow>
                    </TableBody>
                </Table>
                <Divider />
            </div>
            <div className={cx('global!ace-u-margin--bottom-48')}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={6}>{translateTab('table_header.trailer_operation')}</TableCell>
                            <TableCell colSpan={2} />
                            <TableCell colSpan={6} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={6}>
                                {translateTab('table_row_label.roadside_assistance_pickup_and_recovery')}
                            </TableCell>
                            <TableCell>
                                <Icon
                                    icon={camperDimensions ? checkmarkIcon : closeIcon}
                                    className={camperDimensions
                                        ? cx('ace-c-icon--color-success')
                                        : cx('ace-c-icon--color-disabled')}
                                />
                            </TableCell>
                            {!camperDimensions && (
                                <TableCell colSpan={7} />
                            )}
                            {!!camperDimensions && (
                                <TableCell colSpan={7}>
                                    <span className={cx('global!ace-u-margin--right-16')}>
                                        {`${translateTab('table_row_label.until')} ${camperDimensions?.weight || ''} ${translate('global.size_unit.tonne')}`}
                                    </span>
                                    <span className={cx('global!ace-u-margin--right-16')}>
                                        {`${camperDimensions?.height || ''} ${translate('global.size_unit.meter')} ${translateTab('table_row_label.height')}`}
                                    </span>
                                    <span className={cx('global!ace-u-margin--right-16')}>
                                        {`${camperDimensions?.width || ''} ${translate('global.size_unit.meter')} ${translateTab('table_row_label.broad')}`}
                                    </span>
                                    <span className={cx('global!ace-u-margin--right-16')}>
                                        {`${camperDimensions?.length || ''} ${translate('global.size_unit.meter')} ${translateTab('table_row_label.length')}`}
                                    </span>
                                </TableCell>
                            )}
                        </TableRow>
                    </TableBody>
                </Table>
                <Divider />
            </div>
            <div className={cx(['global!ace-u-two-column-layout', 'global!ace-u-margin--bottom-48'])}>
                <div>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={10}>{translateTab('table_header.car_dismantling')}</TableCell>
                                <TableCell colSpan={2} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={10}>
                                    {translateTab('table_row_label.car_dismantling')}
                                </TableCell>
                                <TableCell colSpan={2}>
                                    <Icon
                                        icon={isCarDismantling ? checkmarkIcon : closeIcon}
                                        className={isCarDismantling
                                            ? cx('ace-c-icon--color-success')
                                            : cx('ace-c-icon--color-disabled')}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={10}>
                                    {translateTab('table_row_label.used_car_station')}
                                </TableCell>
                                <TableCell colSpan={2}>
                                    <Icon
                                        icon={usedCarStation ? checkmarkIcon : closeIcon}
                                        className={usedCarStation
                                            ? cx('ace-c-icon--color-success')
                                            : cx('ace-c-icon--color-disabled')}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Divider />
                </div>
            </div>
        </Panel>
    );
};

ContractPartnerOperatingFeaturesPanel.propTypes = {
    contractPartner: PropTypes.object,
};

ContractPartnerOperatingFeaturesPanel.defaultProps = {
    contractPartner: null,
};

const mapStateToProps = (state, props) => {
    const contractPartnerSelector = contractPartnerSelectors.createContractPartnerSelector();

    return {
        contractPartner: contractPartnerSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(ContractPartnerOperatingFeaturesPanel));
