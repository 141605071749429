import React, {useState, useMemo, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import debounce from 'lodash.debounce';
import {snakeCase} from 'change-case';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {ampVehicleFuelTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, SearchBox, NoResultsBlock, Badge} from '@ace-de/ui-components';
import {Table, TableCaption, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {Icon, vehicleIcon, editIcon, InteractiveIcon} from '@ace-de/ui-components/icons';
import FailedRequestBlock from '../../application/ui-elements/FailedRequestBlock';
import * as memberActionTypes from '../memberActionTypes';
import * as memberSelectors from '../memberSelectors';
import * as requestStateSelectors from '../../application/requestStateSelectors';
import config from '../../config';
import ecsAccessControl from '../../ecsAccessControl';
import {ecsFeatureActions, ecsFeatures} from '../../application/ecsFeatures';

const MemberVehiclesCorporatePanel = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('member_vehicles_corporate_panel');
    const {member, memberVehicles, searchVehicles, vehicleSearchResults, initiateEditMemberVehicleFlow} = props;
    const {requestState} = props;
    const {isInProgress, translationKey, error} = requestState;
    const [searchQueryString, setSearchQueryString] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const searchVehiclesDebounced = useMemo(() => debounce(searchVehicles, 250), [searchVehicles]);
    const isVehicleEditingFeatureAvailable = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.MEMBER_VEHICLE,
        ecsFeatureActions.UPDATE,
    );

    const handleOnChange = searchValue => {
        setSearchQueryString(searchValue);
        setSearchResults(memberVehicles);
        if (searchValue) {
            searchVehiclesDebounced({searchQueryString: searchValue, membershipNo: member.membershipNo});
        }
    };

    useEffect(() => {
        setSearchResults(vehicleSearchResults);
    }, [vehicleSearchResults]);

    const corporateMemberVehicles = searchQueryString ? searchResults : memberVehicles;

    const sortedVehicles = corporateMemberVehicles.length > 0
        ? corporateMemberVehicles.slice().sort((memberVehicleA, memberVehicleB) => {
            return memberVehicleA.licensePlateNumber > memberVehicleB.licensePlateNumber ? 1 : -1;
        })
        : corporateMemberVehicles;

    return (
        <Panel
            className={cx('ace-c-panel--full-bleed-content')}
            title={translateScreen('vehicle_panel.title')}
            actions={(
                <SearchBox
                    value={searchQueryString}
                    onChange={handleOnChange}
                    isDropDownDisabled={true}
                    placeholder={translateScreen('input_placeholder.search')}
                />
            )}
        >
            <Table qaIdent="member-corporate-vehicles">
                {memberVehicles.length === 0 && !error && !isInProgress && (
                    <TableCaption>
                        <NoResultsBlock
                            icon={(
                                <Icon
                                    className={cx('ace-c-icon--xxl')}
                                    icon={vehicleIcon}
                                />
                            )}
                            message={translateScreen('vehicle_no_results.message')}
                            description={translateScreen('vehicle_no_results.description')}
                        />
                    </TableCaption>
                )}
                {!isInProgress && !!error && (
                    <TableCaption>
                        <FailedRequestBlock translationKey={translationKey} />
                    </TableCaption>
                )}
                {memberVehicles.length > 0 && sortedVehicles.length === 0 && !error && !isInProgress && (
                    <TableCaption>
                        <NoResultsBlock
                            icon={(
                                <Icon
                                    className={cx('ace-c-icon--xxl')}
                                    icon={vehicleIcon}
                                />
                            )}
                            message={translateScreen('vehicle_no_plate_results.message')}
                            description={translateScreen('vehicle_no_plate_results.description')}
                        />
                    </TableCaption>
                )}
                <TableHead>
                    <TableRow>
                        <TableCell qaIdentPart="vehicle-manufacturer">
                            {translateScreen('vehicle_table_cell.manufacturer')}
                        </TableCell>
                        <TableCell qaIdentPart="vehicle-model">
                            {translateScreen('vehicle_table_cell.model')}
                        </TableCell>
                        <TableCell qaIdentPart="vehicle-licence-plate-number">
                            {translateScreen('vehicle_table_cell.license_plate_number')}
                        </TableCell>
                        <TableCell qaIdentPart="vehicle-build-year">
                            {translateScreen('vehicle_table_cell.build_year')}
                        </TableCell>
                        <TableCell qaIdentPart="vehicle-color">
                            {translateScreen('vehicle_table_cell.color')}
                        </TableCell>
                        <TableCell qaIdentPart="vehicle-fuel-type">
                            {translateScreen('vehicle_table_cell.fuel_type')}
                        </TableCell>
                        <TableCell qaIdentPart="vehicle-weight">
                            {translateScreen('vehicle_table_cell.weight_in_tons')}
                        </TableCell>
                        <TableCell />
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedVehicles.length > 0 && !isInProgress && !error && sortedVehicles.map(vehicle => (
                        <TableRow
                            key={vehicle.id}
                            qaIdentPart={vehicle.id}
                        >
                            <TableCell
                                qaIdentPart="vehicle-manufacturer"
                                qaIdentPartPostfix={vehicle.id}
                            >
                                {vehicle.manufacturer}
                            </TableCell>
                            <TableCell
                                qaIdentPart="vehicle-model"
                                qaIdentPartPostfix={vehicle.id}
                            >
                                {vehicle.model}
                            </TableCell>
                            <TableCell
                                qaIdentPart="vehicle-licence-plate-number"
                                qaIdentPartPostfix={vehicle.id}
                            >
                                {vehicle.licensePlateNumber}
                            </TableCell>
                            <TableCell
                                qaIdentPart="vehicle-build-year"
                                qaIdentPartPostfix={vehicle.id}
                            >
                                {vehicle.buildYear}
                            </TableCell>
                            <TableCell
                                qaIdentPart="vehicle-color"
                                qaIdentPartPostfix={vehicle.id}
                            >
                                {translate(`global.vehicle_color.${(vehicle.color).toLowerCase()}`)}
                            </TableCell>
                            <TableCell
                                qaIdentPart="vehicle-fuel-type"
                                qaIdentPartPostfix={vehicle.id}
                            >
                                {translate(`global.vehicle_fuel_type.${snakeCase(ampVehicleFuelTypes[vehicle.fuelType])}`)}
                            </TableCell>
                            <TableCell
                                qaIdentPart="vehicle-weight"
                                qaIdentPartPostfix={vehicle.id}
                            >
                                {vehicle.weight}
                            </TableCell>
                            <TableCell>
                                {vehicle.isVehicleBCNav && (
                                    <Badge
                                        className={cx('global!ace-u-margin--right-4')}
                                    >
                                        {translate('global.vehicle_source.bc_nav')}
                                    </Badge>
                                )}
                            </TableCell>
                            <TableCell
                                qaIdentPart="vehicle-option"
                                qaIdentPartPostfix={vehicle.id}
                            >
                                {!config.IS_FALLBACK_SYSTEM && isVehicleEditingFeatureAvailable && (
                                    <InteractiveIcon
                                        icon={editIcon}
                                        onClick={() => initiateEditMemberVehicleFlow({vehicleId: vehicle.id})}
                                    />
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Panel>
    );
};
MemberVehiclesCorporatePanel.propTypes = {
    member: PropTypes.object,
    memberVehicles: PropTypes.array,
    searchVehicles: PropTypes.func.isRequired,
    vehicleSearchResults: PropTypes.array,
    initiateEditMemberVehicleFlow: PropTypes.func.isRequired,
    requestState: PropTypes.object,
};

MemberVehiclesCorporatePanel.defaultProps = {
    member: null,
    memberVehicles: [],
    vehicleSearchResults: [],
    requestState: null,
};

const mapStateToProps = (state, props) => {
    const memberByMembershipNoSelector = memberSelectors.createMemberByMembershipNoSelector();
    const memberVehiclesSelector = memberSelectors.createMemberVehiclesSelector();
    const createRequestStateSelector = requestStateSelectors
        .createRequestStateSelector(memberActionTypes.FETCH_MEMBER_VEHICLES_REQUEST);
    return {
        member: memberByMembershipNoSelector(state, props),
        memberVehicles: memberVehiclesSelector(state, props),
        vehicleSearchResults: memberSelectors.getVehicleSearchResults(state, props),
        requestState: createRequestStateSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    searchVehicles: payload => dispatch({type: memberActionTypes.SEARCH_VEHICLES, payload}),
    initiateEditMemberVehicleFlow: payload => dispatch({
        type: memberActionTypes.INITIATE_EDIT_MEMBER_VEHICLE_FLOW,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MemberVehiclesCorporatePanel));
