import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, useRouteUnmountEffect} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {ampVehicleTypes, ampMemberTariffAdditionalPackageTypes, efServiceAssignmentTypes, ampMemberTariffGroups, apmContractPartnerAssignmentChannelTypes} from '@ace-de/eua-entity-types';
import {useStyles, ContentBlock, ContentItem} from '@ace-de/ui-components';
import * as userProfileSelectors from '../user-profiles/userProfileSelectors';
import * as serviceCaseSelectors from '../service-cases/serviceCaseSelectors';
import * as serviceAssignmentSelectors from '../service-assignments/serviceAssignmentSelectors';
import DocumentUploadPanel from '../service-cases/ui-elements/DocumentUploadPanel';
import CommissioningBasicDataPanel from '../service-assignments/ui-elements/CommissioningBasicDataPanel';
import CostCoverageShortTripDataPanel from './ui-elements/CostCoverageShortTripDataPanel';
import CostCoverageCostCoveragePanel from './ui-elements/CostCoverageCostCoveragePanel';
import CostCoverageCostOverviewPanel from './ui-elements/CostCoverageCostOverviewPanel';
import CommissioningACEPartnerPanel from '../service-assignments/ui-elements/CommissioningACEPartnerPanel';
import * as sastActionTypes from './sastActionTypes';
import * as serviceAssignmentActionTypes from '../service-assignments/serviceAssignmentActionTypes';
import config from '../config';
import isACEPartnerFromDACH from '../service-assignments/isACEPartnerFromDACH';

const SASTCostCoverageTab = props => {
    const {cx} = useStyles();
    const {translate} = useTranslate();
    const {serviceCase, serviceAssignment, users, submitSASTCostCoverageForm, submitCreateSASTPDF} = props;
    const {submitSASTCostCoverageServiceProviderForm} = props;
    const {hasToResetAttachmentSelection, storeHasToResetAttachmentSelection} = props;
    const [selfPayer, setSelfPayer] = useState({
        hasSelfPayerOption: serviceCase?.nonAceMember && serviceCase?.prefix === `${config.ACE_COMMISSIONER_ID}`
            ? true : serviceAssignment.selfPayerOption,
        selfPayerNote: serviceAssignment.selfPayerNote,
    });
    const [maximumBudget, setMaximumBudget] = useState(serviceCase?.maximumBudget || '');
    const [callbackPhoneNo, setCallbackPhoneNo] = useState(serviceAssignment.callbackPhoneNo || '');
    const [fallbackSystemId, setFallbackSystemId] = useState(serviceAssignment?.fallbackSystemId || '');
    const [isFallbackSystemIdEnabled, setIsFallbackSystemIdEnabled] = useState(
        !!serviceAssignment?.isFallbackSystemIdEnabled,
    );
    const [totalCost, setTotalCost] = useState(serviceAssignment.totalCost);
    const [costConfirmedByMember, setIsConfirmedByMember] = useState(!!serviceAssignment.costConfirmedByMember);
    const [attachmentsToSendWithAssignment, setAttachmentsToSendWithAssignment] = useState([]);
    const {member} = serviceCase;
    const {tariffDetails} = member;

    const memberTariff = tariffDetails?.additionalPackages?.includes(ampMemberTariffAdditionalPackageTypes.CAMPER)
    && serviceCase.vehicle?.type === ampVehicleTypes.CAMPER
        ? ampMemberTariffAdditionalPackageTypes.CAMPER
        : tariffDetails?.tariffGroup || '';

    const serviceAssignmentTypeKey = serviceAssignment.assignmentType.toLowerCase();
    let assignmentDefaultText;
    let assignmentCostCoverageText;
    let assignmentHintText;
    let nonAceAssignmentText;
    let aceAssignmentText;

    const formatAssignmentText = (assignmentDefaultText, assignmentCostCoverageText, assignmentHintText) => {
        return assignmentDefaultText
            + (assignmentCostCoverageText ? '\n\n' + assignmentCostCoverageText : '')
            + (assignmentHintText ? '\n\n' + assignmentHintText : '');
    };

    if (serviceCase.prefix === `${config.ACE_COMMISSIONER_ID}`) {
        assignmentDefaultText = translate(`global.default_assignment_text.${serviceAssignmentTypeKey}`);
        assignmentCostCoverageText = serviceAssignmentTypeKey
            && memberTariff
            && memberTariff !== ampMemberTariffGroups.NON_MEMBER ? (
                serviceAssignment.selfPayerOption
                    ? translate(`global.cost_coverage_assignment_text.${serviceAssignmentTypeKey}_self_payer`)
                    : translate(`global.cost_coverage_assignment_text.${serviceAssignmentTypeKey}_${memberTariff.toLowerCase()}`)
            ) : '';
        assignmentHintText = translate(`global.general_hint_assignment_text.${serviceAssignmentTypeKey}`)
            + (serviceAssignment.selfPayerOption
                ? translate(`global.self_payer_hint_assignment_text.${serviceAssignmentTypeKey}`)
                : translate(`global.no_self_payer_hint_assignment_text.${serviceAssignmentTypeKey}`));
        aceAssignmentText = formatAssignmentText(assignmentDefaultText, assignmentCostCoverageText, assignmentHintText);
    } else {
        nonAceAssignmentText = serviceAssignmentTypeKey ? translate(`global.assignment_text_non_ace.${serviceAssignmentTypeKey}`) : '';
    }

    const [assignmentText, setAssignmentText] = useState(aceAssignmentText
        || nonAceAssignmentText
        || serviceAssignment.assignmentText);

    useEffect(() => {
        if (!hasToResetAttachmentSelection) return;
        setAttachmentsToSendWithAssignment([]);
        storeHasToResetAttachmentSelection({hasToResetAttachmentSelection: false});
    }, [hasToResetAttachmentSelection, storeHasToResetAttachmentSelection, setAttachmentsToSendWithAssignment]);

    useEffect(() => {
        setCallbackPhoneNo(serviceAssignment?.callbackPhoneNo);
    }, [serviceAssignment?.callbackPhoneNo]);

    useEffect(() => {
        serviceAssignment.assignmentText
            ? setAssignmentText(serviceAssignment.assignmentText)
            : setAssignmentText(aceAssignmentText || nonAceAssignmentText);
    }, [serviceAssignment.assignmentText, setAssignmentText, aceAssignmentText, nonAceAssignmentText]);

    const onChangeHandler = (key, value) => {
        if (key === 'assignmentText') {
            setAssignmentText(value);
            return;
        }

        if (key === 'hasSelfPayerOption') {
            assignmentHintText = translate(`global.general_hint_assignment_text.${serviceAssignmentTypeKey}`);
            if (value) {
                assignmentHintText += translate(`global.self_payer_hint_assignment_text.${serviceAssignmentTypeKey}`);
                assignmentCostCoverageText = translate(`global.cost_coverage_assignment_text.${serviceAssignmentTypeKey}_self_payer`);
                setSelfPayer({
                    ...selfPayer,
                    [key]: value,
                });
            } else {
                assignmentHintText += translate(`global.no_self_payer_hint_assignment_text.${serviceAssignmentTypeKey}`);
                assignmentCostCoverageText = serviceAssignmentTypeKey
                    && memberTariff
                    && memberTariff !== ampMemberTariffGroups.NON_MEMBER
                    ? translate(`global.cost_coverage_assignment_text.${serviceAssignmentTypeKey}_${memberTariff.toLowerCase()}`)
                    : '';

                setSelfPayer({
                    ...selfPayer,
                    [key]: value,
                    selfPayerNote: '',
                });
            }

            if (serviceCase.prefix !== `${config.ACE_COMMISSIONER_ID}`) return;
            setAssignmentText(assignmentDefaultText + '\n\n' + assignmentCostCoverageText + '\n\n' + assignmentHintText);
            return;
        }

        if (key === 'totalCost') {
            setTotalCost(value);
            return;
        }

        if (key === 'maximumBudget') {
            setMaximumBudget(value);
            return;
        }

        if (key === 'callbackPhoneNo') {
            setCallbackPhoneNo(value);
            return;
        }

        if (key === 'fallbackSystemId') {
            setFallbackSystemId(value);
            return;
        }

        if (key === 'isFallbackSystemIdEnabled') {
            setIsFallbackSystemIdEnabled(value);
            setFallbackSystemId('');
            return;
        }

        if (key === 'costConfirmedByMember') {
            setIsConfirmedByMember(value);
            return;
        }

        if (key === 'selfPayerNote' && value.length <= config.MAXIMUM_TEXT_AREA_CONTENT_LENGTH) {
            setSelfPayer({
                ...selfPayer,
                [key]: value,
            });
        }
    };

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        if (!selfPayer) {
            completeRouteUnmountSideEffect({
                caller: sastActionTypes.SUBMIT_SAST_COST_COVERAGE_FORM,
            });
            return;
        }

        submitSASTCostCoverageForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentData: {
                selfPayerOption: selfPayer.hasSelfPayerOption,
                selfPayerNote: selfPayer.selfPayerNote,
                totalCost,
                callbackPhoneNo,
                fallbackSystemId,
                isFallbackSystemIdEnabled,
                costConfirmedByMember: totalCost - serviceAssignment.totalCoverage <= 0
                    ? false
                    : !!costConfirmedByMember,
                assignmentText,
            },
            serviceCaseData: {
                maximumBudget,
            },
            nonAceMember: serviceCase.nonAceMember,
            attachmentsToSendWithAssignment,
        });
    }, [
        selfPayer,
        serviceAssignment.lineNo,
        serviceAssignment.serviceCaseId,
        submitSASTCostCoverageForm,
        assignmentText,
        maximumBudget,
        serviceCase.nonAceMember,
        totalCost,
        callbackPhoneNo,
        fallbackSystemId,
        isFallbackSystemIdEnabled,
        costConfirmedByMember,
        serviceAssignment.totalCoverage,
        attachmentsToSendWithAssignment,
    ]);

    const handleOnSend = costCoverageData => {
        const serviceAssignmentData = {
            selfPayerOption: selfPayer.hasSelfPayerOption,
            selfPayerNote: selfPayer.selfPayerNote,
            totalCost,
            callbackPhoneNo,
            fallbackSystemId,
            isFallbackSystemIdEnabled,
            costConfirmedByMember: totalCost - serviceAssignment.totalCoverage <= 0
                ? false
                : !!costConfirmedByMember,
            assignmentText,
            ...costCoverageData,
        };

        const serviceCaseData = {
            maximumBudget,
            // patch remaining budget on the case level only when assigning the assignment
            remainingBudget: typeof serviceCase.remainingBudget === 'number'
                ? (totalCost < serviceCase.remainingBudget
                    ? serviceCase.remainingBudget - totalCost : 0)
                : (totalCost < config.DEFAULT_SHORT_TRIP_BUDGET
                    ? config.DEFAULT_SHORT_TRIP_BUDGET - totalCost : 0),
        };

        submitSASTCostCoverageServiceProviderForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            assignmentType: efServiceAssignmentTypes.SHORT_TRIP,
            serviceAssignmentData,
            serviceCaseData,
            serviceCaseType: serviceCase.caseType,
            attachmentsToSendWithAssignment,
        });

        // on send, reset attachments state: see https://computerrock.atlassian.net/browse/ACEECS-5792
        setAttachmentsToSendWithAssignment([]);
    };
    const submitCreatePDF = serviceProviderData => {
        const serviceAssignmentData = {
            selfPayerOption: selfPayer.hasSelfPayerOption,
            selfPayerNote: selfPayer.selfPayerNote,
            totalCost,
            callbackPhoneNo,
            fallbackSystemId,
            isFallbackSystemIdEnabled,
            costConfirmedByMember: totalCost - serviceAssignment.totalCoverage <= 0
                ? false
                : !!costConfirmedByMember,
            assignmentText,
            ...serviceProviderData,
        };

        const serviceCaseData = {
            maximumBudget,
            // patch remaining budget on the case level only when assigning the assignment
            remainingBudget: typeof serviceCase.remainingBudget === 'number'
                ? (totalCost < serviceCase.remainingBudget
                    ? serviceCase.remainingBudget - totalCost : 0)
                : (totalCost < config.DEFAULT_SHORT_TRIP_BUDGET
                    ? config.DEFAULT_SHORT_TRIP_BUDGET - totalCost : 0),
        };

        const isDACH = isACEPartnerFromDACH(serviceAssignment?.acePartner);

        submitCreateSASTPDF({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentType: efServiceAssignmentTypes.SHORT_TRIP,
            serviceAssignmentData,
            serviceCaseData,
            serviceCaseType: serviceCase.caseType,
            attachmentsToSendWithAssignment,
            prefix: serviceCase.prefix,
            nonAceMember: serviceCase.nonAceMember,
            serviceType: isDACH
                ? translate(`global.service_type.${serviceAssignment.assignmentType.toLowerCase()}`)
                : serviceAssignment.assignmentType.toLowerCase(),
        });
    };

    // if no case or assignment don't render
    if (!serviceCase || !serviceAssignment) return null;

    return (
        <ContentBlock
            className={cx([
                'global!ace-u-height--full',
                'global!ace-u-max-height--full',
                'global!ace-u-flex--align-stretch',
            ])}
        >
            <ContentItem className={cx('ace-c-content-item--span-9')}>
                <CommissioningBasicDataPanel
                    selfPayer={selfPayer}
                    maximumBudget={maximumBudget}
                    agentUserProfile={users[serviceAssignment.createdBy]}
                    onChangeHandler={onChangeHandler}
                    callbackPhoneNo={callbackPhoneNo}
                    fallbackSystemId={fallbackSystemId}
                    isFallbackSystemIdEnabled={isFallbackSystemIdEnabled}
                />
                <CostCoverageShortTripDataPanel />
                <CostCoverageCostCoveragePanel
                    assignmentText={assignmentText}
                    onChangeHandler={onChangeHandler}
                />
                <CostCoverageCostOverviewPanel
                    totalCost={totalCost}
                    costConfirmedByMember={costConfirmedByMember}
                    onChangeHandler={onChangeHandler}
                />
            </ContentItem>
            <ContentItem className={cx('ace-c-content-item--span-3')}>
                <CommissioningACEPartnerPanel
                    assignmentChannels={[
                        apmContractPartnerAssignmentChannelTypes.EMAIL_PDF_XML,
                        apmContractPartnerAssignmentChannelTypes.FAX,
                    ]}
                    handleOnAssignmentSubmit={handleOnSend}
                    isConfirmedByMember={serviceAssignment.totalCoverage - totalCost < 0 && !costConfirmedByMember}
                    attachmentsToSendWithAssignment={attachmentsToSendWithAssignment}
                    submitCreatePDFForm={submitCreatePDF}
                />
                {!config.IS_FALLBACK_SYSTEM && (
                    <DocumentUploadPanel
                        attachmentsToSendWithAssignment={attachmentsToSendWithAssignment}
                        setAttachmentsToSendWithAssignment={setAttachmentsToSendWithAssignment}
                    />
                )}
            </ContentItem>
        </ContentBlock>
    );
};

SASTCostCoverageTab.propTypes = {
    submitSASTCostCoverageForm: PropTypes.func.isRequired,
    serviceCase: PropTypes.object,
    serviceAssignment: PropTypes.object,
    users: PropTypes.object,
    submitSASTCostCoverageServiceProviderForm: PropTypes.func.isRequired,
    submitCreateSASTPDF: PropTypes.func.isRequired,
    hasToResetAttachmentSelection: PropTypes.bool,
    storeHasToResetAttachmentSelection: PropTypes.func.isRequired,
};

SASTCostCoverageTab.defaultProps = {
    serviceCase: null,
    serviceAssignment: null,
    users: {},
    hasToResetAttachmentSelection: false,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
        users: userProfileSelectors.getUsers(state),
        hasToResetAttachmentSelection: state.serviceAssignments.hasToResetAttachmentSelection,
    };
};

const mapDispatchToProps = dispatch => ({
    submitSASTCostCoverageForm: payload => dispatch({
        type: sastActionTypes.SUBMIT_SAST_COST_COVERAGE_FORM,
        payload,
    }),
    submitSASTCostCoverageServiceProviderForm: payload => dispatch({
        type: serviceAssignmentActionTypes.SUBMIT_COMMISSIONING_ACE_PARTNER_FORM,
        payload,
    }),
    submitCreateSASTPDF: payload => dispatch({
        type: serviceAssignmentActionTypes.CREATE_SERVICE_ASSIGNMENT_PDF,
        payload,
    }),
    storeHasToResetAttachmentSelection: payload => dispatch({
        type: serviceAssignmentActionTypes.STORE_HAS_TO_RESET_ASSIGNMENT_SELECTION,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SASTCostCoverageTab));
