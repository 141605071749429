/**
 * Command action types
 */
export const SEARCH_SERVICE_CASES = '[service-cases] SEARCH_SERVICE_CASES';
export const SEARCH_SERVICE_CASES_BY_VEHICLE_ID = '[service-cases] SEARCH_SERVICE_CASES_BY_VEHICLE_ID';
export const CREATE_SERVICE_CASE = '[service-cases] CREATE_SERVICE_CASE';
export const INITIATE_CREATE_FOREIGN_ASSISTANCE_CASE_FLOW = '[service-cases] INITIATE_CREATE_FOREIGN_ASSISTANCE_CASE_FLOW';
export const DECLINE_CREATE_FOREIGN_ASSISTANCE_CASE = '[service-cases] DECLINE_CREATE_FOREIGN_ASSISTANCE_CASE';
export const CONFIRM_CREATE_FOREIGN_ASSISTANCE_CASE = '[service-cases] CONFIRM_CREATE_FOREIGN_ASSISTANCE_CASE';
export const INITIATE_SC_DOCUMENT_UPLOAD_FLOW = '[service-cases] INITIATE_SC_DOCUMENT_UPLOAD_FLOW';
export const CONFIRM_SC_DOCUMENT_UPLOAD = '[service-cases] CONFIRM_SC_DOCUMENT_UPLOAD';
export const DECLINE_SC_DOCUMENT_UPLOAD = '[service-cases] DECLINE_SC_DOCUMENT_UPLOAD';
export const INITIATE_SC_DOCUMENT_DELETE_FLOW = '[service-cases] INITIATE_SC_DOCUMENT_DELETE_FLOW';
export const CONFIRM_SC_DOCUMENT_DELETE = '[service-cases] CONFIRM_SC_DOCUMENT_DELETE';
export const DECLINE_SC_DOCUMENT_DELETE = '[service-cases] DECLINE_SC_DOCUMENT_DELETE';
export const INITIATE_EMPTY_CASE_CREATION_FLOW = '[service-cases] INITIATE_EMPTY_CASE_CREATION_FLOW';
export const DECLINE_CREATE_EMPTY_CASE = '[service-cases] DECLINE_CREATE_EMPTY_CASE';
export const CONFIRM_CREATE_EMPTY_CASE = '[service-cases] CONFIRM_CREATE_EMPTY_CASE';
export const START_SERVICE_CASE_LOCK_TIMER = '[service-cases] START_SERVICE_CASE_LOCK_TIMER';
export const SUBMIT_SC_DOCUMENT_DOWNLOAD_FLOW = '[service-cases] SUBMIT_SC_DOCUMENT_DOWNLOAD_FLOW';
export const INITIATE_UPDATE_SC_BASIC_DATA_FLOW = '[service-cases] INITIATE_UPDATE_SC_BASIC_DATA_FLOW';
export const INITIATE_UPDATE_DOCUMENT_DESCRIPTION_FLOW = '[service-cases] INITIATE_UPDATE_DOCUMENT_DESCRIPTION_FLOW';
export const CONFIRM_UPDATE_DOCUMENT_DESCRIPTION = '[service-cases] CONFIRM_UPDATE_DOCUMENT_DESCRIPTION';
export const DECLINE_UPDATE_DOCUMENT_DESCRIPTION = '[service-cases] DECLINE_UPDATE_DOCUMENT_DESCRIPTION';
export const INITIATE_CREATE_SERVICE_CASE_VEHICLE_FLOW = '[service-cases] INITIATE_CREATE_SERVICE_CASE_VEHICLE_FLOW';
export const CONFIRM_CREATE_SERVICE_CASE_VEHICLE = '[service-cases] CONFIRM_CREATE_SERVICE_CASE_VEHICLE';
export const DECLINE_CREATE_SERVICE_CASE_VEHICLE = '[service-cases] DECLINE_CREATE_SERVICE_CASE_VEHICLE';
export const GET_VEHICLE_MANUFACTURERS = '[service-cases] GET_VEHICLE_MANUFACTURERS';
export const GET_VEHICLE_MODELS = '[service-cases] GET_VEHICLE_MODELS';
export const GET_VEHICLE_VARIANTS = '[service-cases] GET_VEHICLE_VARIANTS';
export const SUBMIT_SERVICE_CASE_VEHICLE_DATA_FORM = '[service-cases] SUBMIT_SERVICE_CASE_VEHICLE_DATA_FORM';
export const INITIATE_SELECT_SERVICE_CASE_VEHICLE_FLOW = '[service-cases] INITIATE_SELECT_SERVICE_CASE_VEHICLE_FLOW';
export const CONFIRM_SELECT_SERVICE_CASE_VEHICLE = '[service-cases] CONFIRM_SELECT_SERVICE_CASE_VEHICLE';
export const DECLINE_SELECT_SERVICE_CASE_VEHICLE = '[service-cases] DECLINE_SELECT_SERVICE_CASE_VEHICLE';
export const INITIATE_EDIT_SERVICE_CASE_VEHICLE_FLOW = '[service-cases] INITIATE_EDIT_SERVICE_CASE_VEHICLE_FLOW';
export const CONFIRM_EDIT_SERVICE_CASE_VEHICLE = '[service-cases] CONFIRM_EDIT_SERVICE_CASE_VEHICLE';
export const DECLINE_EDIT_SERVICE_CASE_VEHICLE = '[service-cases] DECLINE_EDIT_SERVICE_CASE_VEHICLE';
export const INITIATE_DELETE_SERVICE_CASE_FLOW = '[service-cases] INITIATE_DELETE_SERVICE_CASE_FLOW';
export const CONFIRM_DELETE_SERVICE_CASE_FLOW = '[service-cases] CONFIRM_DELETE_SERVICE_CASE_FLOW';
export const DECLINE_DELETE_SERVICE_CASE_FLOW = '[service-cases] DECLINE_DELETE_SERVICE_CASE_FLOW';
export const TOGGLE_ONGOING_LEGAL_CASE = '[service-cases] TOGGLE_ONGOING_LEGAL_CASE';

/**
 * Event action types
 */
export const BATCH_UPDATE_SERVICE_CASE = '[service-cases] BATCH_UPDATE_SERVICE_CASE';
export const BATCH_UPDATE_SERVICE_CASE_COMPLETED = '[service-cases] BATCH_UPDATE_SERVICE_CASE_COMPLETED';

export const SEARCH_SERVICE_CASES_REQUEST = '[service-cases] SEARCH_SERVICE_CASES_REQUEST';
export const SEARCH_SERVICE_CASES_REQUEST_SENT = '[service-cases] SEARCH_SERVICE_CASES_REQUEST_SENT';
export const SEARCH_SERVICE_CASES_REQUEST_SUCCEEDED = '[service-cases] SEARCH_SERVICE_CASES_REQUEST_SUCCEEDED';
export const SEARCH_SERVICE_CASES_REQUEST_FAILED = '[service-cases] SEARCH_SERVICE_CASES_REQUEST_FAILED';

export const SEARCH_SERVICE_CASES_BY_CASE_IDS_REQUEST = '[service-cases] SEARCH_SERVICE_CASES_BY_CASE_IDS_REQUEST';
export const SEARCH_SERVICE_CASES_BY_CASE_IDS_REQUEST_SENT = '[service-cases] SEARCH_SERVICE_CASES_BY_CASE_IDS_REQUEST_SENT';
export const SEARCH_SERVICE_CASES_BY_CASE_IDS_REQUEST_SUCCEEDED = '[service-cases] SEARCH_SERVICE_CASES_BY_CASE_IDS_REQUEST_SUCCEEDED';
export const SEARCH_SERVICE_CASES_BY_CASE_IDS_REQUEST_FAILED = '[service-cases] SEARCH_SERVICE_CASES_BY_CASE_IDS_REQUEST_FAILED';

export const FETCH_SERVICE_CASE_REQUEST = '[service-cases] FETCH_SERVICE_CASE_REQUEST';
export const FETCH_SERVICE_CASE_REQUEST_SENT = '[service-cases] FETCH_SERVICE_CASE_REQUEST_SENT';
export const FETCH_SERVICE_CASE_REQUEST_SUCCEEDED = '[service-cases] FETCH_SERVICE_CASE_REQUEST_SUCCEEDED';
export const FETCH_SERVICE_CASE_REQUEST_FAILED = '[service-cases] FETCH_SERVICE_CASE_REQUEST_FAILED';

export const CREATE_SERVICE_CASE_REQUEST = '[service-cases] CREATE_SERVICE_CASE_REQUEST';
export const CREATE_SERVICE_CASE_REQUEST_SENT = '[service-cases] CREATE_SERVICE_CASE_REQUEST_SENT';
export const CREATE_SERVICE_CASE_REQUEST_SUCCEEDED = '[service-cases] CREATE_SERVICE_CASE_REQUEST_SUCCEEDED';
export const CREATE_SERVICE_CASE_REQUEST_FAILED = '[service-cases] CREATE_SERVICE_CASE_REQUEST_FAILED';

export const UPDATE_SERVICE_CASE_REQUEST = '[service-cases] UPDATE_SERVICE_CASE_REQUEST';
export const UPDATE_SERVICE_CASE_REQUEST_SENT = '[service-cases] UPDATE_SERVICE_CASE_REQUEST_SENT';
export const UPDATE_SERVICE_CASE_REQUEST_SUCCEEDED = '[service-cases] UPDATE_SERVICE_CASE_REQUEST_SUCCEEDED';
export const UPDATE_SERVICE_CASE_REQUEST_FAILED = '[service-cases] UPDATE_SERVICE_CASE_REQUEST_FAILED';

export const FETCH_SERVICE_TYPE_DAMAGE_NODES_REQUEST = '[service-cases] FETCH_SERVICE_TYPE_DAMAGE_NODES_REQUEST';
export const FETCH_SERVICE_TYPE_DAMAGE_NODES_REQUEST_SENT = '[service-cases] FETCH_SERVICE_TYPE_DAMAGE_NODES_REQUEST_SENT';
export const FETCH_SERVICE_TYPE_DAMAGE_NODES_REQUEST_SUCCEEDED = '[service-cases] FETCH_SERVICE_TYPE_DAMAGE_NODES_REQUEST_SUCCEEDED';
export const FETCH_SERVICE_TYPE_DAMAGE_NODES_REQUEST_FAILED = '[service-cases] FETCH_SERVICE_TYPE_DAMAGE_NODES_REQUEST_FAILED';

export const FETCH_SERVICE_CASE_ASSIGNMENTS_REQUEST = '[service-case] FETCH_SERVICE_CASE_ASSIGNMENTS_REQUEST';
export const FETCH_SERVICE_CASE_ASSIGNMENTS_REQUEST_SENT = '[service-case] FETCH_SERVICE_CASE_ASSIGNMENTS_REQUEST_SENT';
export const FETCH_SERVICE_CASE_ASSIGNMENTS_REQUEST_SUCCEEDED = '[service-case] FETCH_SERVICE_CASE_ASSIGNMENTS_REQUEST_SUCCEEDED';
export const FETCH_SERVICE_CASE_ASSIGNMENTS_REQUEST_FAILED = '[service-case] FETCH_SERVICE_CASE_ASSIGNMENTS_REQUEST_FAILED';

export const FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST = '[service-cases] FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST';
export const FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST_SENT = '[service-cases] FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST_SENT';
export const FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST_SUCCEEDED = '[service-cases] FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST_SUCCEEDED';
export const FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST_FAILED = '[service-cases] FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST_FAILED';

export const SEARCH_MEMBER_ADDRESS_GEOLOCATION_REQUEST = '[service-case] SEARCH_MEMBER_ADDRESS_GEOLOCATION_REQUEST';
export const SEARCH_MEMBER_ADDRESS_GEOLOCATION_REQUEST_SENT = '[service-case] SEARCH_MEMBER_ADDRESS_GEOLOCATION_REQUEST_SENT';
export const SEARCH_MEMBER_ADDRESS_GEOLOCATION_REQUEST_SUCCEEDED = '[service-case] SEARCH_MEMBER_ADDRESS_GEOLOCATION_REQUEST_SUCCEEDED';
export const SEARCH_MEMBER_ADDRESS_GEOLOCATION_REQUEST_FAILED = '[service-case] SEARCH_MEMBER_ADDRESS_GEOLOCATION_REQUEST_FAILED';

export const FETCH_REPORTER_CASES_COUNT_REQUEST = '[service-cases] FETCH_REPORTER_CASES_COUNT_REQUEST';
export const FETCH_REPORTER_CASES_COUNT_REQUEST_SENT = '[service-cases] FETCH_REPORTER_CASES_COUNT_REQUEST_SENT';
export const FETCH_REPORTER_CASES_COUNT_REQUEST_SUCCEEDED = '[service-cases] FETCH_REPORTER_CASES_COUNT_REQUEST_SUCCEEDED';
export const FETCH_REPORTER_CASES_COUNT_REQUEST_FAILED = '[service-cases] FETCH_REPORTER_CASES_COUNT_REQUEST_FAILED';

export const FETCH_SERVICE_CASE_COMMISSIONERS_REQUEST = '[service-cases] FETCH_SERVICE_CASE_COMMISSIONERS_REQUEST';
export const FETCH_SERVICE_CASE_COMMISSIONERS_REQUEST_SENT = '[service-cases] FETCH_SERVICE_CASE_COMMISSIONERS_REQUEST_SENT';
export const FETCH_SERVICE_CASE_COMMISSIONERS_REQUEST_SUCCEEDED = '[service-cases] FETCH_SERVICE_CASE_COMMISSIONERS_REQUEST_SUCCEEDED';
export const FETCH_SERVICE_CASE_COMMISSIONERS_REQUEST_FAILED = '[service-cases] FETCH_SERVICE_CASE_COMMISSIONERS_REQUEST_FAILED';

export const FETCH_SERVICE_MEMBER_APP_CASE_REQUEST = '[service-cases] FETCH_SERVICE_MEMBER_APP_CASE_REQUEST';
export const FETCH_SERVICE_MEMBER_APP_CASE_REQUEST_SENT = '[service-cases] FETCH_SERVICE_MEMBER_APP_CASE_REQUEST_SENT';
export const FETCH_SERVICE_MEMBER_APP_CASE_REQUEST_SUCCEEDED = '[service-cases] FETCH_SERVICE_MEMBER_APP_CASE_REQUEST_SUCCEEDED';
export const FETCH_SERVICE_MEMBER_APP_CASE_REQUEST_FAILED = '[service-cases] FETCH_SERVICE_MEMBER_APP_CASE_REQUEST_FAILED';

export const SEND_MESSAGE_REQUEST = '[service-cases] SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_REQUEST_SENT = '[service-cases] SEND_MESSAGE_REQUEST_SENT';
export const SEND_MESSAGE_REQUEST_SUCCEEDED = '[service-cases] SEND_MESSAGE_REQUEST_SUCCEEDED';
export const SEND_MESSAGE_REQUEST_FAILED = '[service-cases] SEND_MESSAGE_REQUEST_FAILED';

export const LOCK_SERVICE_CASE_REQUEST = '[service-cases] LOCK_SERVICE_CASE_REQUEST';
export const LOCK_SERVICE_CASE_REQUEST_SENT = '[service-cases] LOCK_SERVICE_CASE_REQUEST_SENT';
export const LOCK_SERVICE_CASE_REQUEST_SUCCEEDED = '[service-cases] LOCK_SERVICE_CASE_REQUEST_SUCCEEDED';
export const LOCK_SERVICE_CASE_REQUEST_FAILED = '[service-cases] LOCK_SERVICE_CASE_REQUEST_FAILED';

export const UNLOCK_SERVICE_CASE_REQUEST = '[service-cases] UNLOCK_SERVICE_CASE_REQUEST';
export const UNLOCK_SERVICE_CASE_REQUEST_SENT = '[service-cases] UNLOCK_SERVICE_CASE_REQUEST_SENT';
export const UNLOCK_SERVICE_CASE_REQUEST_SUCCEEDED = '[service-cases] UNLOCK_SERVICE_CASE_REQUEST_SUCCEEDED';
export const UNLOCK_SERVICE_CASE_REQUEST_FAILED = '[service-cases] UNLOCK_SERVICE_CASE_REQUEST_FAILED';

export const DOWNLOAD_SC_DOCUMENT_REQUEST = '[service-cases] DOWNLOAD_SC_DOCUMENT_REQUEST';
export const DOWNLOAD_SC_DOCUMENT_REQUEST_SENT = '[service-cases] DOWNLOAD_SC_DOCUMENT_REQUEST_SENT';
export const DOWNLOAD_SC_DOCUMENT_REQUEST_SUCCEEDED = '[service-cases] DOWNLOAD_SC_DOCUMENT_REQUEST_SUCCEEDED';
export const DOWNLOAD_SC_DOCUMENT_REQUEST_FAILED = '[service-cases] DOWNLOAD_SC_DOCUMENT_REQUEST_FAILED';

export const FIND_SERVICE_CASES_FOR_OPEN_ASSIGNMENTS_REQUEST = '[service-cases] FIND_SERVICE_CASES_FOR_OPEN_ASSIGNMENTS';
export const FIND_SERVICE_CASES_FOR_OPEN_ASSIGNMENTS_REQUEST_SENT = '[service-cases] FIND_SERVICE_CASES_FOR_OPEN_ASSIGNMENTS_REQUEST_SENT';
export const FIND_SERVICE_CASES_FOR_OPEN_ASSIGNMENTS_REQUEST_SUCCEEDED = '[service-cases] FIND_SERVICE_CASES_FOR_OPEN_ASSIGNMENTS_REQUEST_SUCCEEDED';
export const FIND_SERVICE_CASES_FOR_OPEN_ASSIGNMENTS_REQUEST_FAILED = '[service-cases] FIND_SERVICE_CASES_FOR_OPEN_ASSIGNMENTS_REQUEST_FAILED';

export const FETCH_SERVICE_CASE_FOR_SICK_PERSON_AND_VEHICLE_TRANSPORT_ASSIGNMENTS_REQUEST = '[service-cases] FETCH_SERVICE_CASE_FOR_SICK_PERSON_AND_VEHICLE_TRANSPORT_ASSIGNMENTS_REQUEST';
export const FETCH_SERVICE_CASE_FOR_SICK_PERSON_AND_VEHICLE_TRANSPORT_ASSIGNMENTS_REQUEST_SENT = '[service-cases] FETCH_SERVICE_CASE_FOR_SICK_PERSON_AND_VEHICLE_TRANSPORT_ASSIGNMENTS_REQUEST_SENT';
export const FETCH_SERVICE_CASE_FOR_SICK_PERSON_AND_VEHICLE_TRANSPORT_ASSIGNMENTS_REQUEST_SUCCEEDED = '[service-cases] FETCH_SERVICE_CASE_FOR_SICK_PERSON_AND_VEHICLE_TRANSPORT_ASSIGNMENTS_REQUEST_SUCCEEDED';
export const FETCH_SERVICE_CASE_FOR_SICK_PERSON_AND_VEHICLE_TRANSPORT_ASSIGNMENTS_REQUEST_FAILED = '[service-cases] FETCH_SERVICE_CASE_FOR_SICK_PERSON_AND_VEHICLE_TRANSPORT_ASSIGNMENTS_REQUEST_FAILED';

export const FIND_SERVICE_CASE_COUNT_FORM_MEMBERS_REQUEST = '[service-cases] FIND_SERVICE_CASE_COUNT_FORM_MEMBERS_REQUEST';
export const FIND_SERVICE_CASE_COUNT_FORM_MEMBERS_REQUEST_SENT = '[service-cases] FIND_SERVICE_CASE_COUNT_FORM_MEMBERS_REQUEST_SENT';
export const FIND_SERVICE_CASE_COUNT_FORM_MEMBERS_REQUEST_SUCCEEDED = '[service-cases] FIND_SERVICE_CASE_COUNT_FORM_MEMBERS_REQUEST_SUCCEEDED';
export const FIND_SERVICE_CASE_COUNT_FORM_MEMBERS_REQUEST_FAILED = '[service-cases] FIND_SERVICE_CASE_COUNT_FORM_MEMBERS_REQUEST_FAILED';

export const FETCH_VEHICLE_MANUFACTURERS_REQUEST = '[service-cases] FETCH_VEHICLE_MANUFACTURERS_REQUEST';
export const FETCH_VEHICLE_MANUFACTURERS_REQUEST_SENT = '[service-cases] FETCH_VEHICLE_MANUFACTURERS_REQUEST_SENT';
export const FETCH_VEHICLE_MANUFACTURERS_REQUEST_SUCCEEDED = '[service-cases] FETCH_VEHICLE_MANUFACTURERS_REQUEST_SUCCEEDED';
export const FETCH_VEHICLE_MANUFACTURERS_REQUEST_FAILED = '[service-cases] FETCH_VEHICLE_MANUFACTURERS_REQUEST_FAILED';

export const FETCH_VEHICLE_MODELS_REQUEST = '[service-cases] FETCH_VEHICLE_MODELS_REQUEST';
export const FETCH_VEHICLE_MODELS_REQUEST_SENT = '[service-cases] FETCH_VEHICLE_MODELS_REQUEST_SENT';
export const FETCH_VEHICLE_MODELS_REQUEST_SUCCEEDED = '[service-cases] FETCH_VEHICLE_MODELS_REQUEST_SUCCEEDED';
export const FETCH_VEHICLE_MODELS_REQUEST_FAILED = '[service-cases] FETCH_VEHICLE_MODELS_REQUEST_FAILED';

export const FETCH_VEHICLE_VARIANTS_REQUEST = '[service-cases] FETCH_VEHICLE_VARIANTS_REQUEST';
export const FETCH_VEHICLE_VARIANTS_REQUEST_SENT = '[service-cases] FETCH_VEHICLE_VARIANTS_REQUEST_SENT';
export const FETCH_VEHICLE_VARIANTS_REQUEST_SUCCEEDED = '[service-cases] FETCH_VEHICLE_VARIANTS_REQUEST_SUCCEEDED';
export const FETCH_VEHICLE_VARIANTS_REQUEST_FAILED = '[service-cases] FETCH_VEHICLE_VARIANTS_REQUEST_FAILED';

export const DELETE_SERVICE_CASE_REQUEST = '[service-cases] DELETE_SERVICE_CASE_REQUEST';
export const DELETE_SERVICE_CASE_REQUEST_SENT = '[service-cases] DELETE_SERVICE_CASE_REQUEST_SENT';
export const DELETE_SERVICE_CASE_REQUEST_SUCCEEDED = '[service-cases] DELETE_SERVICE_CASE_REQUEST_SUCCEEDED';
export const DELETE_SERVICE_CASE_REQUEST_FAILED = '[service-cases] DELETE_SERVICE_CASE_REQUEST_FAILED';

export const FETCH_SERVICE_CASES_FOR_VEHICLES_REQUEST = '[service-cases] FETCH_SERVICE_CASES_FOR_VEHICLES_REQUEST';
export const FETCH_SERVICE_CASES_FOR_VEHICLES_REQUEST_SENT = '[service-cases] FETCH_SERVICE_CASES_FOR_VEHICLES_REQUEST_SENT';
export const FETCH_SERVICE_CASES_FOR_VEHICLES_REQUEST_SUCCEEDED = '[service-cases] FETCH_SERVICE_CASES_FOR_VEHICLES_REQUEST_SUCCEEDED';
export const FETCH_SERVICE_CASES_FOR_VEHICLES_REQUEST_FAILED = '[service-cases] FETCH_SERVICE_CASES_FOR_VEHICLES_REQUEST_FAILED';

/**
 * Store action types
 */
export const SET_ARE_SEARCH_RESULTS_VALID = '[service-cases] SET_ARE_SEARCH_RESULTS_VALID';
export const SET_SERVICE_CASE_CREATION_PROGRESS_STATE = '[service-cases] SET_SERVICE_CASE_CREATION_PROGRESS_STATE';
export const STORE_SERVICE_CASE_SEARCH_RESULTS = '[service-cases] STORE_SERVICE_CASE_SEARCH_RESULTS';
export const SET_SERVICE_CASE_PERSISTENCE_STATE = '[service-cases] SET_SERVICE_CASE_PERSISTENCE_STATE';
export const STORE_SERVICE_CASES = '[service-cases] STORE_SERVICE_CASES';
export const SET_SERVICE_CASE_ASSIGNMENTS = '[service-cases] SET_SERVICE_CASE_ASSIGNMENTS';
export const SET_SERVICE_TYPE = '[service-cases] SET_SERVICE_TYPE';
export const SET_IS_CALLBACK_PHONE_NUMBER_VALID = '[service-cases] SET_IS_CALLBACK_PHONE_NUMBER_VALID';
export const SET_DAMAGE_TYPE = '[service-cases] SET_DAMAGE_TYPE';
export const SET_DAMAGE_NODE_SNAPSHOT = '[service-cases] SET_DAMAGE_NODE_SNAPSHOT';
export const CLEAR_DAMAGE_NODE_SNAPSHOT = '[service-cases] CLEAR_DAMAGE_NODE_SNAPSHOT';
export const SET_DAMAGE_DESCRIPTION = '[service-cases] SET_DAMAGE_DESCRIPTION';
export const STORE_SERVICE_TYPE_DAMAGE_NODES = '[service-cases] STORE_SERVICE_TYPE_DAMAGE_NODES';
export const STORE_SERVICE_CASE_COMMISSIONERS = '[service-cases] STORE_SERVICE_CASE_COMMISSIONERS';
export const SET_SAV_REVERSE_GEOCODING_DAMAGE_LOCATION = '[service-cases] SET_SAV_REVERSE_GEOCODING_DAMAGE_LOCATION';
export const RESET_SAV_REVERSE_GEOCODING_DAMAGE_LOCATION = '[service-cases] RESET_SAV_REVERSE_GEOCODING_DAMAGE_LOCATION';
export const SET_SAV_DAMAGE_LOCATION_CANDIDATES = '[service-cases] SET_SAV_DAMAGE_LOCATION_CANDIDATES';
export const SET_SERVICE_CASE_REMAINING_LOCK_TIME = '[service-cases] SET_SERVICE_CASE_REMAINING_LOCK_TIME';
export const STORE_VEHICLE_MANUFACTURERS = '[service-cases] STORE_VEHICLE_MANUFACTURERS';
export const STORE_VEHICLE_MODELS = '[service-cases] STORE_VEHICLE_MODELS';
export const STORE_VEHICLE_VARIANTS = '[service-cases] STORE_VEHICLE_VARIANTS';
export const STORE_CASE_LOG_FILTER_RESULTS = '[service-cases] STORE_CASE_LOG_FILTER_RESULTS';
export const SET_SERVICE_CASE_LOCK_STATUS = '[service-cases] SET_SERVICE_CASE_LOCK_STATUS';
export const REMOVE_DELETED_SERVICE_ASSIGNMENTS = '[service-cases] REMOVE_DELETED_SERVICE_ASSIGNMENTS';
