import {ampMemberStatusTypes} from '@ace-de/eua-entity-types';

// NOTE: according to https://computerrock.atlassian.net/browse/ACEECS-2158,
// calculation matrix does NOT depend on the member's tariff
export default {
    [ampMemberStatusTypes.EMPTY]: {
        budgetPerDay: 55,
        totalBudget: 385,
    },
    [ampMemberStatusTypes.SILVER]: {
        budgetPerDay: 60,
        totalBudget: 420,
    },
    [ampMemberStatusTypes.GOLD]: {
        budgetPerDay: 60,
        totalBudget: 420,
    },
    [ampMemberStatusTypes.PLATINUM]: {
        budgetPerDay: 75,
        totalBudget: 525,
    },
};
